function DateComponent(props: any) {

    let date = props.date;

    let color = 'black';
    if (date <= Date.now()) {
        color = 'red';
    }

    if (date === undefined) return <></>

    if (isNaN(date.getTime())) return <></>
    let sdate = date.toISOString().split('T')[0];
    let sDay = sdate.substring(8, 10)
    let sMonth = sdate.substring(5, 7)
    let sYear = sdate.substring(0, 4)

    //to account for DateTime.MinValue in backend
    if (sYear === '0001') return <></>

    return <div className="formIcon">
        <div className="svgIcon svgIcon--calendar" style={{ "left": "0px" }}>
            <svg
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <path
                    d="M3 20a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H5m16 16a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h14m-3-2v4M8 6V2m-5 8h18"
                />
            </svg>
        </div>

        <div style={{ "display": "inline", "marginLeft": "20px", "color": color }}>{sDay+"."+sMonth+"."+sYear}</div>
    </div>
}

export default DateComponent;