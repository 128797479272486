function handleDependents(question: any, currentValue?: string | boolean | undefined) {
    if (question.dependentMatchValues && question.dependentMatchValues.length > 0) {
        let hasMatch = checkDependentMatch(question, currentValue);

        question.dependentHideItemIds.forEach((questionId: any) => {
            let form = document.getElementById("formquestion-" + questionId);
            if (form) {
                form.style.display = hasMatch ? "none" : "block";
            }
        });
        question.dependentShowItemIds.forEach((questionId: any) => {
            let form = document.getElementById("formquestion-" + questionId);
            if (form) {
                form.style.display = hasMatch ? "block" : "none";
            }
        });
    }
}

function checkDependentMatch(question: any, currentValue?: string | boolean | undefined): boolean | undefined {
    if (typeof currentValue === 'undefined') {
        currentValue = getCurrentValueFromnQuestion(question);
        // console.log("currentValue");
        // console.log(currentValue);
    }
    if (typeof currentValue == 'undefined') {
        return undefined;
    }

    switch (question.itemType) {
        case 2: // YesNo
            let boolToMatch = question.dependentMatchValues.map((x: any) => x.radio);
            return boolToMatch.some((x: any) => x === currentValue);
        case 6: // Drop down
            let dropdownToMatch = question.dependentMatchValues.map((x: any) => x.dropdown);
            return dropdownToMatch.some((x: any) => x === currentValue);
        case 7: // Bar code
            let barcodeToMatch = question.dependentMatchValues.map((x: any) => x.barcode);
            return (currentValue == "" && barcodeToMatch.some((x: any) => x == null)) || // Need to match "" and null
                barcodeToMatch.some((x: any) => x === currentValue);
        case 21: // Deviation
            let deviationToMatch = question.dependentMatchValues.map((x: any) => x.deviation);
            return deviationToMatch.some((x: any) => x === currentValue);
    }
}

function getCurrentValueFromnQuestion(question: any) {
    switch (question.itemType) {
        case 2: // YesNo
            if (question.answer !== null) {
                return question.answer.yesNo;
            }
            else {
                if (question.defaultValue?.yesNo === true) {
                    return true;
                }
                return undefined; // Treat false and not selected as no value
            }
        case 6: // Drop down
            return question.answer !== null ? question.answer.DropList.DropIdSelected : question.defaultValue?.dropList.dropIdSelected
        case 7: // Bar code
            return question.answer !== null ? question.answer?.text : question.defaultValue?.text;
        case 21: // Deviation
            return question.answer !== null ? question.answer.deviation : (question.defaultValue?.deviation === true);
    }
}

export default { checkDependentMatch, handleDependents }
