import { useEffect, useState } from "react";
import WorkorderService from "../services/workorder";

function DecimalNumberQuestion(props: any) {
    let [isInvalid, setIsInvalid] = useState(false);
    let [state, setState] = useState({
        value: props.question.defaultValue.decimalNumber
    })

    useEffect(() => {
        setIsInvalid(props.question.isMandatory && state.value === "");
    });

    let handleInputChange = (event: any) => {
        let targetName = event.target.name;
        let targetValue = event.target.value;
        let updatedAnswer = targetValue;

        if (updatedAnswer === "") {
            updatedAnswer = 0;
        }

        //regex check, only update server if regex is ok
        let error = false;

        var regex = /^[0-9]+(\.)?[0-9]*$/;
        if (regex.test(updatedAnswer) == false) {
            setIsInvalid(true);
            error = true;
        } else {
            setIsInvalid(props.question.isMandatory && state.value === "");
        };

        setState({ ...state, [targetName]: targetValue });

        if (error) {
            return;
        }

        WorkorderService.getInstance().UpdateAnswer(props.question.id, props.question.questionType, updatedAnswer);
    };

    function LabelText() {
        if (props.question.isMandatory == true) {
            return (<>
                <label className="black">{props.question.label}</label><span style={{ color: "red" }}>*</span>
            </>)
        } else {
            return (
                <label className="black">{props.question.label}</label>
            )
        }
    }

    return (<>
        <LabelText /><br></br>
        <input autoComplete="off" type="number" name="value" value={state.value} className={`grid-cell w100 w50--m w70--l ${isInvalid ? "is-invalid" : ""}`} onChange={handleInputChange} />
    </>)

}

export default DecimalNumberQuestion;