import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

let lang="";
if(localStorage.getItem('Language')=="" || localStorage.getItem('Language')== null){
    lang='no'
}else{
    lang=localStorage.getItem('Language')
} 

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: lang,
  resources: {
    en: {
      translations: require('./locales/en/translations.json')
    },
    no: {
      translations: require('./locales/no/translations.json')
    },
    se: {
        translations: require('./locales/se/translations.json')
      }
  },
  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = ['en', 'no', 'se'];

export default i18n;