class DebugService {
    private static instance: DebugService;

    static getInstance(): DebugService {
        if (!DebugService.instance) {
            DebugService.instance = new DebugService();
        }

        return DebugService.instance;
    }

    private constructor() { }

    public Debug(o: any): void {
        this.DebugVerbose(o, 3);
    }

    public DebugVerbose(o: any, level: number): void {
        switch (level) {
            case 1: // Critical, console+cloud
                console.error(o);
                // TODO: cloud logging
                break;
            case 2: // Warning, console+cloud
                console.warn(o);
                // TODO: cloud logging
                break;
            case 3: // Information, console
                console.log(o);
                break;
        }
    }

}

export default DebugService;