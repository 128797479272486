import '../i18n/config';
import { useTranslation } from 'react-i18next';
import ApplicationVersion from '../version';

function AboutPage(props: any) {
    const { t } = useTranslation();
    // TODO: Find a solution for loading this from config. Should not use packages.json as it seems to deprecated.
    const applicationVersion = "1.0.4";
    return (
        <div className="section">
            <div className="grid">
                <div className="wrap portalAppHeaderMargin">
                    <h1 className="pBs bdrBs mBs app-border accent100">{t('about')}</h1>
                    <div className="w100 w100--ns w100--m">
                        <div className="grid-row">
                            <div className="grid-cell w100">
                                {t('aboutText')}
                                <br></br><br></br>
                                <b>{t('support')}</b>
                                <br></br>
                                {t('support-text1')}<br></br>
                                <a href="mailto:support@volue.com">support@volue.com</a><br></br>
                                {t('support-text2')}<br></br><br></br>
                                {t('support-text3')}<br></br><br></br>
                                <a href="https://www.volue.com/privacy-statement" target="_new">{t('privacyStatement')}</a><br /><br />
                                <div style={{ float: "right"}}>
                                    <ApplicationVersion />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default AboutPage;