import React, { useContext, useEffect, useState } from "react";
import Signature from "../components/Signature";
import TextQuestion from "../components/TextQuestion";
import TextAreaQuestion from "../components/TextAreaQuestion";
import WholeNumberQuestion from "../components/WholeNumberQuestion";
import DecimalNumberQuestion from "../components/DecimalNumberQuestion";
import DropBoxQuestion from "../components/DropBoxQuestion";
import YesNoQuestion from "../components/YesNoQuestion";
import CameraQuestion from "../components/CameraQuestion";
import SessionService from "../services/session";
import EanScannerComponent from "../components/EanScannerComponent";
import TextInformation from "../components/TextInformation";
import MeterInformation from "../components/MeterInformation";
import CheckboxInformation from "../components/CheckboxInformation";
import '../i18n/config';
import { useTranslation } from 'react-i18next';
import DatabaseService from "../services/database";
import { Box, Button, Dialog } from '@volue/wave-react';
import { LoaderOverlayContext } from "../context/LoaderOverlay";
import BarCodeQuestion from "../components/BarCodeQuestion";
import BarCodeForVerificationQuestion from "../components/BarCodeForVerificationQuestion";
import BarCodeWithVerificationQuestion from "../components/BarCodeWithVerificationQuestion";
import HttpService from "../services/http";
import findQuestion from "../helpers/findQuestion";
import meterIsNoMatch from "../helpers/meterIsNoMatch";
import MandatoryFields from "../helpers/MandatoryFields";
import SetDateProduction from "../components/SetDateProduction";
import TextMultiLine from "../components/TextMultiLine";
import UnknownQuestionType from "../components/UnknownQuestionType";
import { useToastManager } from '@volue/wave-react';
import WorkorderService from "../services/workorder";
import DeviationQuestion from "../components/DeviationQuestion";
import { GridContext } from "../helpers/gridContext";

var Wave = require('@volue/wave');

function Item(props: any) {

    // TODO something
    let item = props.item;

    let itemId = `formquestion-${props.item.id}`;

    let input = <UnknownQuestionType question={props.item} />
    switch (props.item.itemType) {
        case 1:
            input = <TextQuestion question={props.item} />
            break;
        case 2:
            input = <YesNoQuestion question={props.item} />
            break;
        case 3:
            input = <WholeNumberQuestion question={props.item} />;
            break;
        case 4:
            input = <DecimalNumberQuestion question={props.item} />;
            break;
        case 5:
            //input = <ImageQuestion question={props.item} />
            input = <CameraQuestion question={props.item} />
            break;
        case 6:
            input = <DropBoxQuestion question={props.item} />
            break;
        case 7:
            input = <BarCodeQuestion question={props.item} setScanEanCode={props.setScanEanCode} />
            break;
        case 10:
            input = <TextInformation question={props.item} />
            break;
        case 11:
            input = <TextInformation question={props.item} />
            break;
        case 12:    // "free" comment field
            input = <TextAreaQuestion question={props.item} />
            break;
        case 13:
            input = <TextInformation question={props.item} itemType="tel:"/>
            break;
        case 14:
            input = <TextInformation question={props.item} itemType="mailto:"/>
            break;
        case 15:
            input = <CheckboxInformation question={props.item} />
            break;
        case 16:
            input = <MeterInformation question={props.item} />
            break;
        case 17:
            input = <Signature question={props.item} />
            break;
        case 18:
            input = <TextInformation question={props.item} />
            break;
        case 19:
            input = <SetDateProduction question={props.item} />
            break;
        case 20:
            input = <TextMultiLine question={props.item} />
            break;
        case 21:
            input = <DeviationQuestion question={props.item} />
            break;
        case 22:
            input = <BarCodeWithVerificationQuestion question={props.item} setScanEanCode={props.setScanEanCode} />
            break;
        case 23:
            input = <BarCodeForVerificationQuestion question={props.item} setScanEanCode={props.setScanEanCode} />
            break;
    }

    let questionStyle = {
        padding: "10px"
    }

    if (props.item.itemType === 2) {
        return (
            <div id={itemId}>
                {input}
            </div>
        )
    }
    else if (props.item.itemType === 6 && props.item.id === "terminal_blir_ComType") {
        let gridContext = new GridContext(props.gridPrefix);
        return (
            <div id={itemId} style={questionStyle}>
                <h5>{props.item.label}</h5>
                {input}
                { gridContext.showImageNoticeText ? 
                    <div className="notification notification--warning mBm">
                        <div className="notification-icon">
                            <div className="svgIcon svgIcon--warning">
                                <svg
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                >
                                    <path
                                        d="M10.29 3.86 1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0ZM12 9v4m0 4h.01"
                                    />
                                </svg>
                            </div>
                        </div>
                        {"For direktekoblede målere: Koble ut anlegget bak måler vha. målerens interne bryter. Det skal etter utkobling være spenning inn på måler (1, 4, 7) og spenningsløst mot anlegg (3, 6, 9). Mål både fas-fas og fas-jord!"}
                        <br />
                        <br />
                        {"Etter at rett kobling er verifisert vha. målerens interne bryter, kobles anlegget inn igjen."}
                    </div>
                    : <></>
                }
            </div>
        )
    }
    // else if (props.item.itemType === 15 && props.item.id === "maaler_er_Plombert") {
    //     let gridContext = new GridContext(props.gridPrefix);
    //     return (
    //         <div id={itemId} style={questionStyle}>
    //             <h5>{props.item.label}</h5>
    //             {input}
    //             { gridContext.showImageNoticeText ? 
    //                 <div className="notification notification--warning mBm">
    //                     <br />
    //                     <div className="notification-icon">
    //                         <div className="svgIcon svgIcon--warning">
    //                             <svg
    //                                 viewBox="0 0 24 24"
    //                                 xmlns="http://www.w3.org/2000/svg"
    //                                 fill="none"
    //                                 stroke="currentColor"
    //                                 stroke-width="2"
    //                                 stroke-linecap="round"
    //                                 stroke-linejoin="round"
    //                             >
    //                                 <path
    //                                     d="M10.29 3.86 1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0ZM12 9v4m0 4h.01"
    //                                 />
    //                             </svg>
    //                         </div>
    //                     </div>
    //                     { "Etter at rett kobling er verifisert vha. målerens interne bryter, kobles anlegget inn igjen." }
    //                 </div>
    //                 : <></>
    //             }
    //         </div>
    //     )
    // }
    else if (props.item.itemType === 18 || 13) {
        return (
            <div id={itemId}>
                {input}
            </div>
        )
    }
    else {
        return (
            <div id={itemId} style={questionStyle}>
                <h5>{props.item.label}</h5>

                {input}
            </div>
        )
    }
}

function ItemList(props: any) {
    let Items = props.Items.map((e: any) => <Item key={e.id} item={e}
        setTakeImage={props.setTakeImage}
        setScanEanCode={props.setScanEanCode}
        gridPrefix={props.gridPrefix} />
    );

    return <div>{Items}</div>
}

function Section(props: any) {
    return (
        <div className="w100 w100--ns w100--m">
            <h1 className="pBs bdrBs mBs app-border accent100">{props.section.label}</h1>
            <div className="grid-row">
                <div className="grid-cell w80">
                    <div className="mTs measure">
                        <ItemList Items={props.section.items}
                            setTakeImage={props.setTakeImage}
                            setScanEanCode={props.setScanEanCode}
                            gridPrefix={props.gridPrefix} />
                    </div>
                </div>
            </div>
        </div>
    )
}

function SectionsList(props: any) {
    let sections = props.sections.map((e: any) => <Section key={e.id} section={e}
        setTakeImage={props.setTakeImage}
        setScanEanCode={props.setScanEanCode}
        gridPrefix={props.gridPrefix} />
    );
    return (<>
        <PageValidation missing={props.missing} sections={props.sections} pageNumber={props.pageNumber} />
        <div>{sections}</div>
    </>);
}

function PageValidation(props: any) {
    const { t } = useTranslation();
    if (props.missing.filter((x: any) => x.pageNumber == props.pageNumber).length > 0) {
        return (<>
            <div className="notification notification--error mBm" role="alert">
                <div className="grid-row">
                    <h4>{t('followingFieldsMissing')}</h4><br />
                    {props.missing.filter((x: any) => x.pageNumber == props.pageNumber).map((x: any) =>
                        <div className="grid-cell w100" key={"missing-" + x.id}>
                            <div>{x.name}</div>
                        </div>
                    )}
                </div>
            </div>
        </>);
    }
    else {
        return (<></>);
    }
};

function PageNavigation(props: any) {
    if (props.workorder === null) {
        return (<></>);
    }

    let navigationElements = props.workorder.pages.map((e: any) => {
        var listClassNames = "steps-label";

        if (e.order === 0) {
            listClassNames = listClassNames + ' steps-label--is-active';
        }

        return <li key={e.order} className={listClassNames} data-step={e.order}>{e.label}</li>
    });

    setTimeout(function () { props.setNavigationIsLoaded(true); }, 1000);

    return (
        <ol className="fixed nav nav--block steps-nav w100" data-title="Steps">
            {navigationElements}
        </ol>
    )
}

function PageContent(props: any) {
    if (props.workorder === null) {
        return (<></>);
    }

    let gridContext = new GridContext(props.workorder.gridPrefix);
    let pages = props.workorder.pages.map((e: any) => {
        let pageNumber=e.order;
        return (
            <section key={e.id} className="steps-panel" data-step={e.order}>
                <div className="wrap portalAppHeaderMargin">
                    {/* <h1 className="pBs bdrBs mBs app-border accent100">{e.label}</h1> */}
                    <SectionsList pageNumber={pageNumber} sections={e.sections}
                        setTakeImage={props.setTakeImage}
                        setScanEanCode={props.setScanEanCode}
                        missing={props.missing}
                        gridPrefix={props.workorder.gridPrefix} />
                    {
                        pageNumber==2 && gridContext.showImageNoticeText ? 
                        <div className="notification notification--warning mBm">
                            <div className="notification-icon">
                                <div className="svgIcon svgIcon--warning">
                                    <svg
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <path
                                            d="M10.29 3.86 1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0ZM12 9v4m0 4h.01"
                                        />
                                    </svg>
                                </div>
                            </div>
                            Dersom oppgaven kan utføres skal du nå ta et oversiktsbilde av tavle med åpen dør før arbeidet påbegynnes.
                            Bildet lagres på telefonen for opplastning til APP på et senere tidspunkt.                            
                        </div>
                        : <></> 
                    }
                </div>
            </section>
        )
    });

    setTimeout(function () { props.setPagesAreLoaded(true); }, 1000);

    return (
        <>
            {pages}
        </>
    )
}

async function IsMeterNumberCorrect(pageNumber: number, selectedWorkorderId: string): Promise<boolean | undefined> {
    return DatabaseService.getInstance().getStoredWorkorderAsync(selectedWorkorderId).then(workorder => {
        if (workorder !== null && workorder.pages !== null && workorder.pages.length >= pageNumber) {
            // console.log("Checking meter number correct for page " + pageNumber + " with id " + workorder.pages[pageNumber - 1].id);
            let questionsOnCurrentPage = workorder.pages[pageNumber - 1].sections.flatMap((x: any) => x.items);

            let processBlockerQuestionIds = ["maaler_nr", "maaler_blir_nr"];
            for (let i = 0; i < processBlockerQuestionIds.length; i++) {
                let question = findQuestion(questionsOnCurrentPage, processBlockerQuestionIds[i]);
                if (meterIsNoMatch(question)) {
                    console.log(question.id + " is " + question.answer?.text + ". Expected " + question.expectedValue);
                    return false;
                }
            }
        }
        return true;
    });
}

async function IsTrafoNumberCorrect(pageNumber: number, selectedWorkorderId: string): Promise<boolean | undefined> {
    return DatabaseService.getInstance().getStoredWorkorderAsync(selectedWorkorderId).then(workorder => {
        if (workorder !== null && workorder.pages !== null && workorder.pages.length >= pageNumber) {
            // console.log("Checking meter number correct for page " + pageNumber + " with id " + workorder.pages[pageNumber - 1].id);
            let questionsOnCurrentPage = workorder.pages[pageNumber - 1].sections.flatMap((x: any) => x.items);

            let processBlockerQuestionIds = ["trafo_blir_StromTrafo1", "trafo_blir_StromTrafo2", "trafo_blir_StromTrafo3"];
            for (let i = 0; i < processBlockerQuestionIds.length; i++) {
                let question = findQuestion(questionsOnCurrentPage, processBlockerQuestionIds[i]);
                if (meterIsNoMatch(question)) {
                    console.log(question.id + " is " + question.answer?.text + ". Expected " + question.expectedValue);
                    return false;
                }
            }
        }
        return true;
    });
}

function IsWorkBlocked(pageNumber: number, selectedWorkorderId: any): Promise<boolean> {
    if (pageNumber === 1 || pageNumber === 2) { // The work preparation tab can only be in position 1 or 2
        return DatabaseService.getInstance().getStoredWorkorderAsync(selectedWorkorderId).then(workorder => {
            if (workorder?.pages?.length != null && workorder?.pages?.length > pageNumber - 1 && workorder.pages[pageNumber - 1].id === "stepWorkPreparation_Name") {
                let questionsOnCurrentPage = workorder.pages[pageNumber - 1].sections.flatMap((x: any) => x.items);
                // ItemType 2 means YesNo. All YesNo questions comes from Elsmart item type RADIOPROCESSBLOCKER
                let yesNos = questionsOnCurrentPage.filter((x: any) => x.itemType === 2);

                for (let yesNo of yesNos) {
                    if (yesNo.answer?.yesNo === false) {
                        return false;
                    }
                    else if (!yesNo.answer && yesNo.defaultValue?.yesNo === false) {
                        return false;
                    }
                }
            }

            return true;
        });
    } else {
        return Promise.resolve(true);
    }
}

function WorkorderPage(props: any) {
    const toastManager = useToastManager();
    const { t } = useTranslation();
    const { hideLoader, showLoader } = useContext(LoaderOverlayContext);

    let [workorder, setWorkorder] = useState(null)

    let [scanEanCode, setScanEanCode] = useState(false);
    let [takeImage, setTakeImage] = useState(false);

    let [isCompleted, setIsCompleted] = useState(false);

    let [navigationIsLoaded, setNavigationIsLoaded] = useState(false);
    let [pagesAreLoaded, setPagesAreLoaded] = useState(false);

    let [currentStep, setCurrentStep] = useState(1);

    const [showWorkBlockedWarning, setShowWorkBlockedWarning] = React.useState(false);
    const [meterDontMatchWarning, setMeterDontMatchWarning] = React.useState(false);
    const [trafoDontMatchWarning, setTrafoDontMatchWarning] = React.useState(false);
    const [missing, setMissing] = React.useState<any[]>([]);

    useEffect(() => {
        if (pagesAreLoaded) {
            if (navigationIsLoaded) {
                if (document.getElementsByClassName("js-stepsWizard").length >= 1) {
                    let wizardLoaded = false;
                    Array.from(document.getElementsByClassName("js-stepsWizard")).forEach((el) => {
                        if (el.classList.contains('loaded')) {
                            wizardLoaded = true;
                        }
                        el.classList.add('loaded');
                    });
                    if (wizardLoaded) {
                        // We need to initialized wizard the first time page is loaded and whenever we navigate from bar code reader back to steps.
                        // Could be done better. Maybe by changing how we navigate to and from the bar code scanner.
                        return;
                    }

                    var wizard = Wave.StepsWizard(
                        document.querySelector('.js-stepsWizard'),
                        {
                            selectedItem: {
                                step: currentStep
                            },
                            addClickHandlers: true,
                            enableOverview: true,
                            shouldScrollToTop: true
                        }
                    );

                    wizard.on('navigate', async (e: any) => {
                        // The navigate with step next holds the last visited page index as e.step
                        if (e.direction === "next") {
                            setCurrentStep(parseInt(e.step) + 1);
                            if (WorkorderService.getInstance().HasQueuedOrOngoingUpdates()) {
                                if (showLoader) showLoader();
                                while (WorkorderService.getInstance().HasQueuedOrOngoingUpdates()) {
                                    await new Promise(f => setTimeout(f, 100));
                                }
                                if (hideLoader) hideLoader();
                            }

                            let isWorkBlocked = await IsWorkBlocked(e.step, selectedWorkorderId);
                            if (!isWorkBlocked) {
                                wizard.previous();
                                let missing = await MandatoryFields.listMissingProcessBlockerDependents(e.step, selectedWorkorderId);
                                setMissing(missing);
                                if (missing.length === 0) {
                                    setShowWorkBlockedWarning(true);
                                }
                            } else {
                                let isMeterNumberCorrect = await IsMeterNumberCorrect(e.step, selectedWorkorderId);
                                if (isMeterNumberCorrect === false) {
                                    wizard.previous();
                                    let missing = await MandatoryFields.listMissingProcessBlockerDependents(e.step, selectedWorkorderId);
                                    setMissing(missing);
                                    if (missing.length === 0) {
                                        // console.log("setMeterDontMatchWarning");
                                        setMeterDontMatchWarning(true);
                                    }
                                } else {
                                    let isTrafoNumberCorrect = await IsTrafoNumberCorrect(e.step, selectedWorkorderId);
                                    if (isTrafoNumberCorrect === false) {
                                        wizard.previous();
                                        let missing = await MandatoryFields.listMissingProcessBlockerDependents(e.step, selectedWorkorderId);
                                        setMissing(missing);
                                        if (missing.length === 0) {
                                            // console.log("setTrafoDontMatchWarning");
                                            setTrafoDontMatchWarning(true);
                                        }
                                    } else {
                                        let missing = await MandatoryFields.listMissingMandatoryQuestions(e.step, selectedWorkorderId);
                                        setMissing(missing);
                                        if (missing.length > 0) {
                                            wizard.previous();
                                        }
                                    }
                                }
                            }
                        }
                        else {
                            setCurrentStep(parseInt(e.step) - 1);
                        }
                    });

                    wizard.on('back', function (e: any) {
                        window.location.href = "/";
                    });

                    wizard.on('finish', async (e: any) => {
                        if (WorkorderService.getInstance().HasQueuedOrOngoingUpdates()) {
                            if (showLoader) showLoader();
                            while (WorkorderService.getInstance().HasQueuedOrOngoingUpdates()) {
                                await new Promise(f => setTimeout(f, 100));
                            }
                            if (hideLoader) hideLoader();
                        }

                        for (let pageNumber = 1; pageNumber <= wizard._totalSteps; pageNumber++) {
                            let missing = await MandatoryFields.listMissingMandatoryQuestions(pageNumber, selectedWorkorderId);
                            if (pageNumber == wizard._totalSteps || missing.length > 0) {
                                setMissing(missing);
                            }
                            if (missing.length > 0) {
                                wizard.selectedItem({ step: pageNumber });
                                return;
                            }
                        }

                        // console.log("Marking work order as finished");
                        let workorder = await DatabaseService.getInstance().getStoredWorkorderAsync(selectedWorkorderId);
                        workorder.finished = true;

                        await UpdateWithErrorCheckAndRedirect((workorder: any) => {
                            return HttpService.getInstance().updateWorkorder(workorder)
                        }, workorder);
                    });
                }
            }
        }

        let selectedWorkorderId = SessionService.getInstance().getSelectedWorkorderId();

        if (showLoader) showLoader();

        DatabaseService.getInstance().getStoredWorkorderAsync(selectedWorkorderId).then(res => {
            if (res === null) {
                // Error: workorder is not stored locally
                // TODO: handle this, show message or something
                debugger;
            }

            setWorkorder(res);
            setIsCompleted(res.isCompleted);

            if (hideLoader) hideLoader();

            if (res.signature !== null) {
                //setSignature(res.signature);
            }
        });

    }, [pagesAreLoaded, navigationIsLoaded, takeImage, setTakeImage, scanEanCode, currentStep, setCurrentStep]);

    if (scanEanCode) {
        return <EanScannerComponent setScanEanCode={setScanEanCode} />
    }
    else {
        return (
            <>
                <div className="steps js-stepsWizard">
                    <div className="steps-content">
                        <PageContent workorder={workorder}
                            setPagesAreLoaded={setPagesAreLoaded}
                            setTakeImage={setTakeImage}
                            setScanEanCode={setScanEanCode}
                            missing={missing} />
                    </div>

                    <div role="navigation" aria-label="Steps">
                        <PageNavigation workorder={workorder} setNavigationIsLoaded={setNavigationIsLoaded} />

                        <button className="steps-button btn fL" type="button" data-cancel-text={t('cancel')} data-cancel-icon-class="pf pf-close">
                            <span className="iconCircle iconCircle--outline"><i className="pf pf-arrow-left"></i></span>
                            <span className="mLm steps-button-label">{t('previous')}</span>
                        </button>

                        <button id="next" className="steps-button btn fR" type="button" data-submit-text={t('submit')} data-submit-icon-class="pf pf-check">
                            <span className="steps-button-label mRm">{t('next')}</span>
                            <span className="iconCircle iconCircle--outline"><i className="pf pf-arrow-right"></i></span>
                        </button>
                    </div>
                </div>

                <ProcessBlockerDialog isOpen={showWorkBlockedWarning} onIsOpenChange={setShowWorkBlockedWarning} text={t('workCannotBeStartedText')} onReport={ButtonCancelWorkorder} />
                <ProcessBlockerDialog isOpen={meterDontMatchWarning} onIsOpenChange={setMeterDontMatchWarning} text={t('meterNumberDontMatch')} onReport={CancelWorkorderNoMatchMeter} />
                <ProcessBlockerDialog isOpen={trafoDontMatchWarning} onIsOpenChange={setTrafoDontMatchWarning} text={t('trafoNumberDontMatch')} onReport={CancelWorkorderTrafoNoMatch} />
            </>
        )
    }

    function ProcessBlockerDialog(props: any) {
        return (
            <Dialog isOpen={props.isOpen} onIsOpenChange={props.onIsOpenChange}>
                <Dialog.Box css={{ padding: '$spacingM' }}>
                    <h3>{props.text}</h3>
                    <Box css={{ mt: '$spacingM' }}>
                        <Dialog.Close as={Button}> {t('close')} </Dialog.Close>
                        <Dialog.Close as={Button} color="red" className=" fR" onClick={props.onReport}> {t('reportToElsmart')} </Dialog.Close>
                    </Box>
                </Dialog.Box>
            </Dialog>
        )
    }

    async function ButtonCancelWorkorder() {
        let selectedWorkorderId = SessionService.getInstance().getSelectedWorkorderId();
        let workorder = await DatabaseService.getInstance().getStoredWorkorderAsync(selectedWorkorderId);

        if (workorder) {
            if (CheckIfProcessBlockByQuestion(workorder, "workCanNotBeStarted")) {
                await UpdateWithErrorCheckAndRedirect((workorder: any) => {
                    return HttpService.getInstance().cannotBeStarted(workorder)
                }, workorder);
            }
            else if (CheckIfProcessBlockByQuestion(workorder, "safeJobAnalysisCompleted")) {
                await UpdateWithErrorCheckAndRedirect((workorder: any) => {
                    return HttpService.getInstance().safeJobAnalysisFailed(workorder)
                }, workorder);

            }
            else if (CheckIfProcessBlockByQuestion(workorder, "facilityPowerDisconnected")) {
                await UpdateWithErrorCheckAndRedirect((workorder: any) => {
                    return HttpService.getInstance().facilityPowerDisconnectedFailed(workorder)
                }, workorder);
            }
        }
    }

    function CheckIfProcessBlockByQuestion(workorder: any, questionId: string) {
        let questions = workorder.pages.flatMap((x: any) => x.sections).flatMap((x: any) => x.items);
        let question = findQuestion(questions, questionId);

        return question && question.itemType === 2 && question.answer?.yesNo === false;
    }

    async function CancelWorkorderNoMatchMeter() {
        let selectedWorkorderId = SessionService.getInstance().getSelectedWorkorderId();
        let workorder = await DatabaseService.getInstance().getStoredWorkorderAsync(selectedWorkorderId);

        if (workorder) {
            let questions = workorder.pages.flatMap((x: any) => x.sections).flatMap((x: any) => x.items);
            if (meterIsNoMatch(findQuestion(questions, "maaler_nr"))) {
                await UpdateWithErrorCheckAndRedirect((workorder: any) => {
                    return HttpService.getInstance().wrongExistingMeterNumber(workorder)
                }, workorder);
            }
            else if (meterIsNoMatch(findQuestion(questions, "maaler_blir_nr"))) {
                await UpdateWithErrorCheckAndRedirect((workorder: any) => {
                    return HttpService.getInstance().wrongMeterNumber(workorder)
                }, workorder);
            }
        }
    }

    async function CancelWorkorderTrafoNoMatch() {
        let selectedWorkorderId = SessionService.getInstance().getSelectedWorkorderId();
        let workorder = await DatabaseService.getInstance().getStoredWorkorderAsync(selectedWorkorderId);

        if (workorder) {
            let questions = workorder.pages.flatMap((x: any) => x.sections).flatMap((x: any) => x.items);
            if (meterIsNoMatch(findQuestion(questions, "trafo_blir_StromTrafo1"))) {
                await UpdateWithErrorCheckAndRedirect((workorder: any) => {
                    return HttpService.getInstance().wrongTransformerNumber(workorder)
                }, workorder);
            }
            else if (meterIsNoMatch(findQuestion(questions, "trafo_blir_StromTrafo2"))) {
                await UpdateWithErrorCheckAndRedirect((workorder: any) => {
                    return HttpService.getInstance().wrongTransformerNumber(workorder)
                }, workorder);
            }
            else if (meterIsNoMatch(findQuestion(questions, "trafo_blir_StromTrafo3"))) {
                await UpdateWithErrorCheckAndRedirect((workorder: any) => {
                    return HttpService.getInstance().wrongTransformerNumber(workorder)
                }, workorder);
            }
        }
    }

    async function UpdateWithErrorCheckAndRedirect(updateFn: (workorder: any) => Promise<any>, workorder: any) {
        if (showLoader) showLoader();
        let updateResponse = await updateFn(workorder);
        if (updateResponse && updateResponse.ok) {
            // Remove work order locally
            await DatabaseService.getInstance().removeFromStoredWorkordersAsync(workorder.unid);
            toastManager.addToast({
                message: `${t('updatedWorkOrder')} ` + workorder.order,
                type: 'success',
                isClosable: true
            });
            if (hideLoader) hideLoader();
            props.setSelectedPage("OverviewPage");
        }
        else if (updateResponse && updateResponse.status === 401) { // token expired
            SessionService.getInstance().removeToken();

            props.setIsLoggedIn(false);
            if (hideLoader) hideLoader();
            props.setSelectedPage('LoginPage');

            toastManager.addToast({
                message: `${t('sessionExpiredLoginAgain')}`,
                type: 'warning',
                isClosable: true
            });
        }
        else {
            if (hideLoader) hideLoader();
            let errorMessage;
            if (updateResponse && updateResponse.status === 500) {
                try {
                    let lang = localStorage.getItem("Language") ?? "no";
                    let response = await updateResponse.json();
                    errorMessage = JSON.parse(response.message).filter((x: any) => x.language.includes(lang))[0].value;
                }
                catch (error) {
                    console.error(error);
                }
            }

            toastManager.addToast({
                message: `${t('failedWhenUpdatingWorkOrder')} ` + workorder.order + (errorMessage ? ". " + errorMessage : ""),
                type: 'error',
                isClosable: true,
                duration: 1000 * 60 * 30 /* 30 min */
            });
        }
    }
}

export default WorkorderPage;