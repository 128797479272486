function TextMultiLine(props: any) {
    return (
        <div className="grid-row">
            <div className="grid-cell w100 w50--m w50--l">{props.question.label}</div>
            <div className="grid-cell w100 w50--m w50--l">
                {props.question.defaultValue?.textList?.map((line: string, index: number) => <span key={props.question.id + "-line" + index}>{line}<br /></span>)}
            </div>
        </div>
    );
}

export default TextMultiLine;
