import { createContext, useState } from 'react';

export class WorkorderId {
    readonly gridId: string;
    readonly unid: string;
    readonly order: string;

    constructor(gridId: string, unid: string, order: string) {
        this.gridId = gridId;
        this.unid = unid;
        this.order = order;
    }
}

interface IWorkorderListContext {
    items: WorkorderId[];
    setItems?: (itemsArray: WorkorderId[]) => void;
    getItems?: () => WorkorderId[];
}

const WorkorderListDefaultState = {
    items: []
};

const WorkorderListContext = createContext<IWorkorderListContext>(WorkorderListDefaultState);

const WorkorderListProvider = ({ children }: { children: any }) => {
    function setItems(workorderIds: WorkorderId[]) {
        localStorage.setItem("selectedWorkoders", JSON.stringify(workorderIds));
    }

    function getItems(): WorkorderId[] {
        let arr = localStorage.getItem("selectedWorkoders");
        if (arr === null) return [];

        return JSON.parse(arr);
    }

    return (
        <WorkorderListContext.Provider value={{ items: [], getItems: getItems, setItems: setItems }}>
            {children}
        </WorkorderListContext.Provider>
    )
}

export { WorkorderListProvider, WorkorderListContext };
