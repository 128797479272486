import React, { useEffect, useState } from "react";
import WorkorderListElement from "../components/WorkorderListElement";
import '../i18n/config';
import { useTranslation } from 'react-i18next';
import DatabaseService from "../services/database";

function WorkorderDeletePage(props: any) {
    const { t } = useTranslation();
    const [workorders, setWorkorders] = useState(<></>);

    useEffect(() => {

        DatabaseService.getInstance().getStoredWorkordersAsync().then((ws: any) => {
            if (ws !== null) {
                if (ws.length === 0) return;

                setWorkorders(ws.map((e: any) => <WorkorderListElement key={e.id} e={e} setSelectedPage={props.setSelectedPage} showSelection={true} />));
            }
        });
    }, [])

    return (

        <>
            <div className="section">
                <div className="grid">
                    <div className="wrap portalAppHeaderMargin">
                        <h1 className="pBs bdrBs mBs app-border accent100">{t('workorderListDeleteTitle')}</h1>
                        <div className="w100">
                            <div className="grid-row">
                                <div className="grid-cell w100">
                                    {workorders}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default WorkorderDeletePage;