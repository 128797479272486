import { useEffect, useState, useContext } from "react";
import WorkorderIcon from "../models/workorderIcon";
import WorkorderListElementModel from "../models/workorderListElementModel";
import SessionService from "../services/session";
import DateComponent from "./DateComponent";
import { WorkorderListContext, WorkorderId } from "../context/WorkorderListContextProvider";

function WorkorderListElement(props: any) {

    const { setItems, getItems } = useContext(WorkorderListContext);

    let [model, setModel] = useState<WorkorderListElementModel | undefined>(undefined);
    let [icon, setIcon] = useState(<></>);
    let [showSelection, setShowSelection] = useState(false);
    let [isActive, setIsActive] = useState(false);

    let [isChecked, setIsChecked] = useState(false);
    let [enableNavigation, setEnableNavigation] = useState(false);

    function OnWorkorderListElementSelection(e: any) {

        let val = e.target.checked;

        if (val === true) {      // chebox is checked
            if (setItems && getItems) {
                let arr = getItems();
                // Listing available work order uses gridId. Single work order uses gridPrefix
                let gridId = props.e.gridId ?? props.e.gridPrefix;

                arr.push(new WorkorderId(gridId, props.e.id, props.e.order));

                setItems(arr);
            }

        } else {                // checkbox is not checked
            if (setItems && getItems) {
                let arr = getItems();

                arr = arr.filter((x) => {
                    return x.unid != props.e.id;
                })

                setItems(arr);
            }

        }
    }

    function SelectList() {
        if (showSelection == true) {
            return (<div><input type="checkbox" id={props.e.id} onChange={OnWorkorderListElementSelection} defaultChecked={isChecked} /><label htmlFor={props.e.id} ></label></div>)
        } else {
            return (<div></div>)
        }
    }

    function Open_OnClick() {
        if (enableNavigation === true) {
            SessionService.getInstance().setSelectedWorkorderId(props.e.id);
            props.setSelectedPage("WorkorderPage");
        }
    }

    useEffect(() => {

        if (props.showSelection !== undefined) {
            setShowSelection(props.showSelection);
        }
        if (props.enableNavigation !== undefined) {
            setEnableNavigation(props.enableNavigation)
        }

        if (model === undefined) {

            let e = props.e;

            let m = new WorkorderListElementModel();
            m.title = `${e?.address?.streetName} ${(e?.address?.streetNumber != null ? e?.address?.streetNumber : "") + (e?.address?.streetLetter != null ? e?.address?.streetLetter : "")}`;
            m.appartmentInfo = e?.address?.apartmentNumber;
            if (e?.address?.floor != null) {
                if (m.appartmentInfo != null) {
                    m.appartmentInfo += `, Etg ` + e?.address?.floor;
                }
                else {
                    m.appartmentInfo = `Etg ` + e?.address?.floor;
                }
            }
            m.address = `${e?.address?.zipCode} ${e?.address?.city}`;
            m.label = e.task === '' || e.task == null ? e.task : 'Type ' + e.task;
            m.date = new Date(e.dueDate?.split('T')[0]);
            m.icon = parseInt(e?.type);
            m.workorderId = e.id;
            m.orderReference = e.order;
            m.NmReference = e.referenceNumber;
            m.meterNumber = e.meterNumber;
            m.meterNumberNew = e.meterNumberNew;


            // kwMeeter does not always equal e.type, so we've replaced it for now.
            /*kwMeeter="0" --> Ny Måler
            kwMeeter = "1" --> Målerbytte
            kwMeeter = "2" --> Målernedtak
            kwMeeter = "3" --> Plombering*/
           
            /*
            switch (e.type) {
                case 0:
                    m.MalerType = 'Ny Måler';
                    break;
                case 1:
                    m.MalerType = 'Målerbytte';
                    break;
                case 2:
                    m.MalerType = 'Målernedtak';
                    break;
                case 3:
                    m.MalerType = 'Plombering';
                    break;
                default:
                    m.MalerType = '';
                    break;
            } */


            //if (m.title.length > 20) m.title = `${m.title.substr(0, 20)} ...`

            setModel(m);
            if (new Date(e?.dueDate).valueOf() <= Date.now()) {
                setIsActive(true)
            } else {
                setIsActive(false)
            }

            switch (m?.icon) {
                // default icon "power"
                case WorkorderIcon.NotDefined:
                    setIcon(
                        <svg
                            aria-hidden="true"
                            focusable="false"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="1"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M22 12h-4l-3 9L9 3l-3 9H2" />
                        </svg>);
                    break;

                // Meter replacement
                case WorkorderIcon.MeterChange:
                    setIcon(<>
                        <svg
                            viewBox="0 0 560 560"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M266.2 471c-71.4.2-131.1-29.4-177.2-90.1-61.8-81.5-57-196.3 10.3-274.7 37.5-43.7 85.1-68.9 142.2-75.7 117-13.8 224.4 66.5 244 182.1.5 2.9 1 5.9 1.3 8.9.8 8.7-3.4 14.5-12.1 14.7-18.1.4-36.2.1-55.1.1v-11.8c-.1-10.1-4.7-15.3-14.9-15.8-16.4-.7-31.7-4.9-45.4-14-5.2-3.5-10.2-7.5-14.8-11.8-9.7-9.3-18.5-19.6-28.6-28.4-19.9-17.2-43-27.3-69.6-28.5-6.7-.3-13.4-.4-20.2-.2-9 .2-14.1 5.5-14.2 14.5-.1 13.2 0 26.5 0 40.3-2.4.1-4.3.3-6.3.3h-73.9c-6.3 0-11.8 1.4-15.1 7.6-4.2 7.9-.1 17.3 8.6 19.4 2.5.6 5.2.8 7.7.8 24.1.1 48.2 0 72.3 0h6.4v82.9h-78.7c-2.8 0-5.6.3-8.3 1-5.7 1.4-9.6 7-9.6 12.9s4.1 11.6 9.6 13c2.9.7 5.9.9 8.9.9 23.9.1 47.8 0 71.7 0h6.8v6.5c0 11.6-.1 23.2.1 34.7.1 8.1 5.1 13.4 13.1 13.9 27.4 1.4 53.7-2.5 76.9-18.4 6.6-4.5 12.8-9.6 18.6-15.1 9.4-8.8 17.8-18.6 27.4-27.3 15-13.5 32.8-20.8 53.2-22 15.3-.9 18.3-4.5 18.3-19.8v-8.5h8.6c17.2-.2 34.4.7 51.5-.8 23-2 37.7-21.1 34.2-44-15.4-100.4-71.1-169-166.1-204.1-54.3-20-110.5-19.5-164.6 1.2C94.2 50 39 114.4 21.9 208.6c-21.4 118 48.3 236.2 160.9 276 27.2 9.6 55 14.3 83.4 14.3V471zm125.2-206.5c-2.4.5-4.6 1-6.8 1.4-21.8 3.6-40.6 13.5-56.7 28.4-10 9.2-19.1 19.5-28.9 28.9-16.4 15.6-35.8 23.6-59 23.6V153.7c22.9.1 42.2 8.4 58.7 23.4 9.1 8.3 17.5 17.4 26.2 26.1 12.3 12.4 26.7 21.6 43.2 27.2 7.7 2.6 15.7 4.2 23.5 6.2-.2 9.2-.2 18.5-.2 27.9z" />
                            <path d="M420 316c-67.4 0-122 54.6-122 122s54.6 122 122 122 122-54.6 122-122-54.6-122-122-122zm-76.3 58.3h116.8v-18.7l38.1 38.1c-12.6 12.6-25.4 25.3-38.1 38.1v-18.4H343.7v-39.1zM499.2 491H382.6v18.5c-12.7-12.7-25.5-25.4-38.1-38l38-38v18.6h116.7V491z" />
                        </svg>
                    </>);
                    break;

                // Meter new
                case WorkorderIcon.NewMeter:
                    setIcon(
                        <svg
                            viewBox="0 0 560 560"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M266.2 471c-71.4.2-131.1-29.4-177.2-90.1-61.8-81.5-57-196.3 10.3-274.7 37.5-43.7 85.1-68.9 142.2-75.7 117-13.8 224.4 66.5 244 182.1.5 2.9 1 5.9 1.3 8.9.8 8.7-3.4 14.5-12.1 14.7-18.1.4-36.2.1-55.1.1v-11.8c-.1-10.1-4.7-15.3-14.9-15.8-16.4-.7-31.7-4.9-45.4-14-5.2-3.5-10.2-7.5-14.8-11.8-9.7-9.3-18.5-19.6-28.6-28.4-19.9-17.2-43-27.3-69.6-28.5-6.7-.3-13.4-.4-20.2-.2-9 .2-14.1 5.5-14.2 14.5-.1 13.2 0 26.5 0 40.3-2.4.1-4.3.3-6.3.3h-73.9c-6.3 0-11.8 1.4-15.1 7.6-4.2 7.9-.1 17.3 8.6 19.4 2.5.6 5.2.8 7.7.8 24.1.1 48.2 0 72.3 0h6.4v82.9h-78.7c-2.8 0-5.6.3-8.3 1-5.7 1.4-9.6 7-9.6 12.9s4.1 11.6 9.6 13c2.9.7 5.9.9 8.9.9 23.9.1 47.8 0 71.7 0h6.8v6.5c0 11.6-.1 23.2.1 34.7.1 8.1 5.1 13.4 13.1 13.9 27.4 1.4 53.7-2.5 76.9-18.4 6.6-4.5 12.8-9.6 18.6-15.1 9.4-8.8 17.8-18.6 27.4-27.3 15-13.5 32.8-20.8 53.2-22 15.3-.9 18.3-4.5 18.3-19.8v-8.5h8.6c17.2-.2 34.4.7 51.5-.8 23-2 37.7-21.1 34.2-44-15.4-100.4-71.1-169-166.1-204.1-54.3-20-110.5-19.5-164.6 1.2C94.2 50 39 114.4 21.9 208.6c-21.4 118 48.3 236.2 160.9 276 27.2 9.6 55 14.3 83.4 14.3V471zm125.2-206.5c-2.4.5-4.6 1-6.8 1.4-21.8 3.6-40.6 13.5-56.7 28.4-10 9.2-19.1 19.5-28.9 28.9-16.4 15.6-35.8 23.6-59 23.6V153.7c22.9.1 42.2 8.4 58.7 23.4 9.1 8.3 17.5 17.4 26.2 26.1 12.3 12.4 26.7 21.6 43.2 27.2 7.7 2.6 15.7 4.2 23.5 6.2-.2 9.2-.2 18.5-.2 27.9z" />
                            <path d="M420 316c-67.4 0-122 54.6-122 122s54.6 122 122 122 122-54.6 122-122-54.6-122-122-122zm85.6 137.9h-69.7v69.7H404v-69.7h-69.7V422H404v-69.7h31.9V422h69.7v31.9z" />
                        </svg>
                    );
                    break;

                // Meter dismounting
                case WorkorderIcon.MeterDisassembly:
                    setIcon(
                        <svg
                            viewBox="0 0 560 560"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M422.6 316c-67.4 0-122 54.6-122 122s54.6 122 122 122 122-54.6 122-122-54.6-122-122-122zm71.8 171.3l-22.5 22.5-49.3-49.3-49.3 49.3-22.5-22.5L400 438l-49.3-49.3 22.5-22.5 49.3 49.3 49.3-49.3 22.5 22.5-49.2 49.3 49.3 49.3z" />
                            <path d="M268.8 471c-71.4.2-131.1-29.4-177.2-90.1-61.8-81.5-57-196.3 10.3-274.7 37.5-43.7 85.1-68.9 142.2-75.7C361 16.7 468.4 97 488.1 212.6c.5 2.9 1 5.9 1.3 8.9.8 8.7-3.4 14.5-12.1 14.7-18.1.4-36.2.1-55.1.1v-11.8c-.1-10.1-4.7-15.3-14.9-15.8-16.4-.7-31.7-4.9-45.4-14-5.2-3.5-10.2-7.5-14.8-11.8-9.7-9.3-18.5-19.6-28.6-28.4-19.9-17.2-43-27.3-69.6-28.5-6.7-.3-13.4-.4-20.2-.2-9 .2-14.1 5.5-14.2 14.5-.1 13.2 0 26.5 0 40.3-2.4.1-4.3.3-6.3.3h-73.9c-6.3 0-11.8 1.4-15.1 7.6-4.2 7.9-.1 17.3 8.6 19.4 2.5.6 5.2.8 7.7.8 24.1.1 48.2 0 72.3 0h6.4v82.9h-78.7c-2.8 0-5.6.3-8.3 1-5.7 1.4-9.6 7-9.6 12.9s4.1 11.6 9.6 13c2.9.7 5.9.9 8.9.9 23.9.1 47.8 0 71.7 0h6.8v6.5c0 11.6-.1 23.2.1 34.7.1 8.1 5.1 13.4 13.1 13.9 27.4 1.4 53.7-2.5 76.9-18.4 6.6-4.5 12.8-9.6 18.6-15.1 9.4-8.8 17.8-18.6 27.4-27.3 15-13.5 32.8-20.8 53.2-22 15.3-.9 18.3-4.5 18.3-19.8v-8.5h8.6c17.2-.2 34.4.7 51.5-.8 23-2 37.7-21.1 34.2-44C501 118.3 445.3 49.7 350.3 14.7 296-5.3 239.8-4.8 185.7 15.9 96.8 50 41.6 114.4 24.5 208.6c-21.4 118 48.3 236.2 160.9 276 27.2 9.6 55 14.3 83.4 14.3V471zM394 264.5c-2.4.5-4.6 1-6.8 1.4-21.8 3.6-40.6 13.5-56.7 28.4-10 9.2-19.1 19.5-28.9 28.9-16.4 15.6-35.8 23.6-59 23.6V153.7c22.9.1 42.2 8.4 58.7 23.4 9.1 8.3 17.5 17.4 26.2 26.1 12.3 12.4 26.7 21.6 43.2 27.2 7.7 2.6 15.7 4.2 23.5 6.2-.2 9.2-.2 18.5-.2 27.9z" />
                        </svg>);
                    break;

                // Meter sealing
                case WorkorderIcon.MeterSealing:
                    setIcon(
                        <svg
                            viewBox="0 0 560 560"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M266.2 471c-71.4.2-131.1-29.4-177.2-90.1-61.8-81.5-57-196.3 10.3-274.7 37.5-43.7 85.1-68.9 142.2-75.7 117-13.8 224.4 66.5 244 182.1.5 2.9 1 5.9 1.3 8.9.8 8.7-3.4 14.5-12.1 14.7-18.1.4-36.2.1-55.1.1v-11.8c-.1-10.1-4.7-15.3-14.9-15.8-16.4-.7-31.7-4.9-45.4-14-5.2-3.5-10.2-7.5-14.8-11.8-9.7-9.3-18.5-19.6-28.6-28.4-19.9-17.2-43-27.3-69.6-28.5-6.7-.3-13.4-.4-20.2-.2-9 .2-14.1 5.5-14.2 14.5-.1 13.2 0 26.5 0 40.3-2.4.1-4.3.3-6.3.3h-73.9c-6.3 0-11.8 1.4-15.1 7.6-4.2 7.9-.1 17.3 8.6 19.4 2.5.6 5.2.8 7.7.8 24.1.1 48.2 0 72.3 0h6.4v82.9h-78.7c-2.8 0-5.6.3-8.3 1-5.7 1.4-9.6 7-9.6 12.9s4.1 11.6 9.6 13c2.9.7 5.9.9 8.9.9 23.9.1 47.8 0 71.7 0h6.8v6.5c0 11.6-.1 23.2.1 34.7.1 8.1 5.1 13.4 13.1 13.9 27.4 1.4 53.7-2.5 76.9-18.4 6.6-4.5 12.8-9.6 18.6-15.1 9.4-8.8 17.8-18.6 27.4-27.3 15-13.5 32.8-20.8 53.2-22 15.3-.9 18.3-4.5 18.3-19.8v-8.5h8.6c17.2-.2 34.4.7 51.5-.8 23-2 37.7-21.1 34.2-44-15.4-100.4-71.1-169-166.1-204.1-54.3-20-110.5-19.5-164.6 1.2C94.2 50 39 114.4 21.9 208.6c-21.4 118 48.3 236.2 160.9 276 27.2 9.6 55 14.3 83.4 14.3V471zm125.2-206.5c-2.4.5-4.6 1-6.8 1.4-21.8 3.6-40.6 13.5-56.7 28.4-10 9.2-19.1 19.5-28.9 28.9-16.4 15.6-35.8 23.6-59 23.6V153.7c22.9.1 42.2 8.4 58.7 23.4 9.1 8.3 17.5 17.4 26.2 26.1 12.3 12.4 26.7 21.6 43.2 27.2 7.7 2.6 15.7 4.2 23.5 6.2-.2 9.2-.2 18.5-.2 27.9z" /><path d="M420 316c-67.4 0-122 54.6-122 122s54.6 122 122 122 122-54.6 122-122-54.6-122-122-122zm-41.5 73.5c1.5-12.5 6.6-23.4 17.5-30.8 4.4-3 9.4-4.7 14.6-5.6 8.9-1.4 17.6-1.2 26.2 1.9 9.4 3.4 16 9.8 20.3 18.7 3.4 7.1 5.2 14.7 5.1 22.7-.1 5.2 0 10.5 0 15.9h-18v-1.7-17.4c0-4.8-1.1-9.2-3.4-13.4-2-3.6-5.1-6-8.9-7.4-7.8-2.9-15.8-3-23.6 0-6.4 2.5-10 7.6-11.4 14.2-.5 2.3-.7 4.8-.7 7.1-.1 5.8 0 11.6 0 17.4 0 .3 0 .6-.1 1.1h-17.8c-.1-.1-.1-.2-.1-.3-.1-7.4-.6-15 .3-22.4zm101.4 124.8H360v-83.8h119.9v83.8z" />
                            <path d="M412.9 476c.9.6 1.1 1.3 1.1 2.3v18h11.9v-1.8-15.4c0-1.5.4-2.5 1.7-3.5 6.3-4.9 5.8-14.8-1-19.1-5.6-3.6-12.7-2.2-16.4 3.1-3.8 5.4-2.7 12.3 2.7 16.4z" />
                        </svg>
                    );
                    break;
                // TODO: add more definitions of icon. See the models/WorkorderIcon.ts for definition

                // default icon "power"
                default:
                    setIcon(
                        <svg
                            aria-hidden="true"
                            focusable="false"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="1"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M22 12h-4l-3 9L9 3l-3 9H2" />
                        </svg>);
                    break;
            }
        }
    }, []);
    
    const RenderMeterNumberSection = () => {
        let e = props.e;

        let meterText = e.type == 0 ? "Ny måler" : "Eksist. måler";
        
        if(e.type != 1) {
          return (
                <>
                    <div className="grid-row" style={{ "marginBottom": "0px" }}>
                        <div className="grid-cell w100">
                            <h4 className="accent120">{meterText}</h4> { e.type == 0 ? <span>{model?.meterNumberNew}</span> : <span>{model?.meterNumber}</span> }
                        </div>
                    </div>
                </>
            )  
        } else {
            return (
                <>
                    <div className="grid-row" style={{ "marginBottom": "0px" }}>
                        <div className="grid-cell w100">
                            <h4 className="accent120">{meterText}</h4><span>{model?.meterNumber}</span>
                        </div>
                    </div>

                    <div className="grid-row" style={{ "marginBottom": "0px" }}>
                        <div className="grid-cell w100">
                            <h4 className="accent120">Ny måler</h4><span>{model?.meterNumberNew}</span>
                        </div>
                    </div>
                </>
            )  
        }
    }

    return (
        <div className="grid">
            <div className="grid-row" onClick={Open_OnClick}>
                <div className="grid-cell" style={{ "marginTop": "40px", "marginRight": "10px" }}>
                    <SelectList />
                </div>
                <div className="grid-cell"></div>
                <div className="grid-cell" style={{ "marginTop": "20px" }}>
                    <div className={`iconCircle iconCircle--large ${isActive ? "fill-red50" : "fill-blue50"}`}>
                        <div className="svgIcon">
                            {icon}
                        </div>
                    </div>
                </div>
                <div className="grid-cell" style={{ "marginLeft": "20px" }}>
                    <div className="grid">
                        <div className="grid-row" style={{ "marginBottom": "0px" }}>
                            <div className="grid-cell w100">
                                <h4>{model?.title}</h4>
                            </div>
                        </div>
                        <div className="grid-row" style={{ "marginBottom": "0px" }}>
                            <div className="grid-cell w100">
                                <h4>{model?.appartmentInfo}</h4>
                            </div>
                        </div>
                        <div className="grid-row" style={{ "marginBottom": "0px" }}>
                            <div className="grid-cell w100">
                                <h4>{model?.address}</h4>
                            </div>
                        </div>

                        <RenderMeterNumberSection />

                        <div className="grid-row" style={{ "marginBottom": "0px" }}>
                            <div className="grid-cell w100">
                                <span className="zeta">{model?.label}</span>
                            </div>
                        </div>

                        <div className="grid-row" style={{ "marginBottom": "0px" }}>
                            <div className="grid-cell w100">
                                <DateComponent date={model?.date} />
                            </div>
                        </div>

                        <div className="grid-row" style={{ "marginBottom": "0px" }}>
                            <div className="grid-cell w100">
                                <span>{model?.orderReference}</span>
                            </div>
                        </div>

                        <div className="grid-row" style={{ "marginBottom": "0px" }}>
                            <div className="grid-cell w100">
                                <span>{model?.NmReference}</span>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default WorkorderListElement;