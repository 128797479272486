import { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useToastManager } from '@volue/wave-react';

import WorkorderListElement from "../components/WorkorderListElement";
import HttpService from "../services/http";
import SessionService from '../services/session';
import '../i18n/config';
import { WorkorderListContext } from "../context/WorkorderListContextProvider";
import { LoaderOverlayContext } from "../context/LoaderOverlay";
import DatabaseService from "../services/database";

function WorkorderListPage(props: any) {
    const toastManager = useToastManager();
    const { t } = useTranslation();
    const { setItems } = useContext(WorkorderListContext);
    const { hideLoader, showLoader } = useContext(LoaderOverlayContext);
    const [workorders, setWorkorders] = useState([]);
    const [workordersComponent, setWorkordersComponent] = useState(<></>);
    const [workordersToDeliver, setWorkordersToDeliver] = useState<any[]>([]);
    const [workordersToDeliverComponent, setWorkordersToDeliverComponent] = useState(<></>);
    const [filter, setFilter] = useState('');
    const [woLength, setWoLength] = useState(0);
    const [woToDeliverLength, setWoToDeliverLength] = useState(0);
    const [hasDownloaded, setHasDownloaded] = useState(false);

    function filterWorkorders(f: string, ws: any) {
        if (filter.length > 0) {
            ws = workorders.filter((e: any) => {
                let searchString = 
                e.address.city + 
                e.address.zipCode + 
                e.address.streetName + ' ' + 
                e.address.streetNumber + 
                e?.address?.streetLetter + 
                e.task + 
                e.meterNumber + 
                e.meterNumberNew + 
                e.order + 
                e.referenceNumber;
                
                return searchString.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
            });
        }

        let wMapped = ws.map((e: any) => <WorkorderListElement key={e.id} e={e} setSelectedPage={props.setSelectedPage} showSelection={true} />);
        setWorkordersComponent(<>{wMapped}</>);
        setWoLength(workorders.length);
    }

    function setWsToDeliver() {
        DatabaseService.getInstance().getStoredWorkordersAsync().then((res:any)=>{
            setWorkordersToDeliver(res);
        });
    }

    function mapWsToDeliver() {
        let wToDeliverMapped = workordersToDeliver.map((e: any) => <WorkorderListElement key={e.id} e={e} setSelectedPage={props.setSelectedPage} showSelection={false} />);
        setWorkordersToDeliverComponent(<>{wToDeliverMapped}</>);
        setWoToDeliverLength(workordersToDeliver.length);
    }

    useEffect(() => {
        filterWorkorders(filter, workorders);
        mapWsToDeliver();
        // Since there is a filter on keystroke we don't want the application to pull the data from the
        // serverer on every keystroke. So only download the list once, taking care if the list is empty
        if (hasDownloaded === false) {
            setHasDownloaded(true);
            if (showLoader) showLoader();

            setWsToDeliver();

            HttpService.getInstance().getWorkorders()
                .then((res: Response | undefined) => {
                    if (res !== undefined) {
                        if (res.ok) {
                            return res.json();
                        } else if (res.status === 401 || res.status === 403) {
                            // token expired (most common), password changed or user locked out
                            SessionService.getInstance().removeToken();

                            props.setIsLoggedIn(false);
                            props.setSelectedPage('LoginPage');

                            let message = res.status === 401 ?
                                `${t('sessionExpiredLoginAgain')}` :
                                `${t('lockedOrMissingPermissionsLoginAgain')}`;

                            toastManager.addToast({
                                message: message,
                                type: 'warning',
                                isClosable: true
                            });
                        } else {
                            toastManager.addToast({
                                message: `${t('errorListingWorkOrders')}`,
                                type: 'error',
                                isClosable: true
                            });
                        }
                    }
                    else {
                        toastManager.addToast({
                            message: `${t('errorListingWorkOrders')}`,
                            type: 'error',
                            isClosable: true
                        });
                    }
                    setTimeout(() => {
                        if (hideLoader) hideLoader();
                    }, 400);
                })
                .then(json => {
                    if (json !== undefined) {
                        setWorkorders(json);

                        // Cannot invoke an object which is possibly 'undefined', therefor check it first
                        if (setItems) setItems([]);
                    }
                    setTimeout(() => {
                        if (hideLoader) hideLoader();
                    }, 400);
                }).catch(reason => {
                    console.error(reason);
                })
        }
    }, [filter, workorders]);

    return (
        <div className="section">
            <div className="grid">
                <div className="wrap portalAppHeaderMargin">
                    <h1 className="pBs bdrBs mBs app-border accent100">{t('workorderListTitle')}</h1>
                    <div className="w100">
                        <div className="grid-row">
                            <div className="grid-cell w100">
                                <ul className="listBlock listBlock--withDivider">
                                    <li className="listBlock-item collapsiblePanel collapsiblePanel--is-expanded">
                                        <a href="#" role="button" className="listBlock-action js-triggerExpand" data-target=".collapsiblePanel">
                                            <div className="flexTable flexTable--middle">
                                                <div className="flexTable-sizeFill listBlock-itemCell">
                                                    <h5 className="accent120">Til levering ({woToDeliverLength})</h5>
                                                </div>
                                                <div className="flexTable-sizeFit listBlock-itemCell">
                                                    <i className="collapsiblePanel-chevron" aria-hidden="true" style={{ "display": woToDeliverLength > 0 ? "block" : "none" }}></i>
                                                </div>
                                            </div>
                                        </a>
                                        <div className="collapsiblePanel-collapse">
                                            {workordersToDeliverComponent}
                                        </div>
                                    </li>
                                    <li className="listBlock-item collapsiblePanel collapsiblePanel--is-expanded">
                                        <a href="#" role="button" className="listBlock-action js-triggerExpand" data-target=".collapsiblePanel">
                                            <div className="flexTable flexTable--middle">
                                                <div className="flexTable-sizeFill listBlock-itemCell">
                                                    <h5 className="accent120">Tilgjengelig ({woLength})</h5>
                                                </div>
                                                <div className="flexTable-sizeFit listBlock-itemCell">
                                                    <i className="collapsiblePanel-chevron" aria-hidden="true" style={{ "display": woLength > 0 ? "block" : "none" }}></i>
                                                </div>
                                            </div>
                                        </a>
                                        <div className="collapsiblePanel-collapse">
                                            <div className="grid-cell w100">
                                                <label className="w100 fL mBs mBn--l pRm--l">
                                                    <span className="formIcon w100">
                                                        <i className="pf pf-search" aria-hidden="true"></i>
                                                        <input autoComplete="off" type="text" name="search" title="Filter the list by searching" placeholder={`${t('search')}`} onChange={(e) => {
                                                            let val = e.target.value;
                                                            setFilter(val);
                                                        }} />
                                                    </span>
                                                </label>
                                            </div>
                                            {workordersComponent}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default WorkorderListPage;
