import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import { useToastManager } from '@volue/wave-react';

import '../i18n/config';
import { WorkorderListContext } from '../context/WorkorderListContextProvider';
import HttpService from '../services/http';
import DatabaseService from '../services/database';
import SessionService from '../services/session';
import { LoaderOverlayContext } from '../context/LoaderOverlay';

function Footer(props: any) {
  const toastManager = useToastManager();
  const { t } = useTranslation();
  const { setItems, getItems } = useContext(WorkorderListContext);
  const { hideLoader, showLoader } = useContext(LoaderOverlayContext);
  const username = SessionService.getInstance().getUsername();
  const [timestampExistsOnUser, setTimestampExistsOnUser] = useState(false)

  let selectedPage = props.selectedPage;

  async function SynchronizeClick() {
    if (getCurrentPrivacyNoticeAccepted().length === 0) {
      ShowPrivacyConsentBox();
    } else {
      let timestamp: string | null = getTimestampOfUser();
      if (timestamp === null) {
        ShowPrivacyConsentBox();
      } else {
        setTimestampExistsOnUser(true);
        let now: number = Math.floor(new Date().getTime() / 1000);
        let oneYear: number = 365 * 24 * 60 * 60;
        let timestampAsNumber: number | null = +timestamp;
        let acceptExpiredOrInvalid: boolean = now > timestampAsNumber + oneYear || timestampAsNumber > now;
        if (acceptExpiredOrInvalid) {
          ShowPrivacyConsentBox();
        } else {
          // All checks related to the privacy consent has passed, download of workorder starts here:
          if (showLoader) showLoader();

          // First post pack all workorders on the app: 
          const storedWorkorders: Array<any> = await DatabaseService.getInstance().getStoredWorkordersAsync();

          for (const workorder of storedWorkorders) {
            // Upload work order to Elsmart
            let updateResponse = await await HttpService.getInstance().updateWorkorder(workorder);
            if (updateResponse) {
              if (updateResponse.ok) {
                // Remove work order locally
                await DatabaseService.getInstance().removeFromStoredWorkordersAsync(workorder.unid);
                toastManager.addToast({
                  message: `${t('updatedWorkOrder')} ` + workorder.order,
                  type: 'success',
                  isClosable: true
                });
              }
              else if (updateResponse.status === 401) { // token expired
                SessionService.getInstance().removeToken();

                props.setIsLoggedIn(false);
                props.setSelectedPage('LoginPage')

                toastManager.addToast({
                  message: `${t('sessionExpiredLoginAgain')}`,
                  type: 'warning',
                  isClosable: true
                });
              }
              else {
                let errorMessage;
                if (updateResponse.status === 500) {
                  try {
                    let lang = localStorage.getItem("Language") ?? "no";
                    let response = await updateResponse.json();
                    errorMessage = JSON.parse(response.message).filter((x: any) => x.language.includes(lang))[0].value;
                  }
                  catch (error) {
                    console.error(error);
                  }
                }

                toastManager.addToast({
                  message: `${t('failedWhenUpdatingWorkOrder')} ` + workorder.order + (errorMessage ? ". " + errorMessage : ""),
                  type: 'error',
                  isClosable: true,
                  duration: 1000 * 60 * 30 /* 30 min */
                });
              }
            } else {
              toastManager.addToast({
                message: `${t('failedWhenUpdatingWorkOrder')} ` + workorder.order,
                type: 'error',
                isClosable: true
              });
            }
          }
          await downloadWorkorder();
        }
      }
    }
  }

  let getTimestampOfUser = (): string | null => {
    let array = getCurrentPrivacyNoticeAccepted();
    let userWithAcceptedWarning: UserWithAcceptedPrivacyWarning | undefined;
    if (array.length > 0) {
      userWithAcceptedWarning = array.find((x: UserWithAcceptedPrivacyWarning) => x.username === username);
    }
    if (userWithAcceptedWarning !== null && userWithAcceptedWarning !== undefined && userWithAcceptedWarning.timestamp !== "") {
      return userWithAcceptedWarning.timestamp;
    } else {
      return null;
    }
  }

  let getCurrentPrivacyNoticeAccepted = (): Array<UserWithAcceptedPrivacyWarning> => {
    // This function returns an empty array in all cases where 'privacyNoticeAccepted' in Local Storage is not a valid array, else returns the array as an object.
    let localStorageCopy: string | null = localStorage.getItem("privacyNoticeAccepted");
    let array: Array<UserWithAcceptedPrivacyWarning> = [];

    if (
      localStorageCopy === null ||
      localStorageCopy === undefined ||
      localStorageCopy === "" ||
      localStorageCopy === "[]" ||
      "privacyNoticeAccepted" in localStorage === false ||
      (localStorageCopy !== null && /[a-zA-Z0-9]/.test(localStorageCopy.substring(0, 1)))
    ) {
      return array;
    } else {
      try {
        array = JSON.parse(localStorageCopy);
        return array;
      }
      catch (err) {
        return array;
      }
    }
  }

  async function downloadWorkorder() {
    if (getItems && setItems) {
      let selectedUnids = getItems();

      await Promise.all(selectedUnids.map(async (workorderId) => {
        try {
          let language = localStorage.getItem("Language") ?? "no";
          let workorderResponse = await HttpService.getInstance().getWorkorder(workorderId.gridId, workorderId.unid, language);
          if (workorderResponse) {
            if (workorderResponse.ok) {
              let workorder = await workorderResponse.json();
              await DatabaseService.getInstance().addToStoredWorkordersAsync(workorder);
              toastManager.addToast({
                message: `${t('downloadedWorkOder')} ` + workorder.order,
                type: 'success',
                isClosable: true
              });
              return;
            }
            else if (workorderResponse.status === 403) {
              let orderOrUnid: string = (workorderId.order !== "" && workorderId.order != null) ? workorderId.order : workorderId.unid;
              toastManager.addToast({
                message: `${t('unableToDownloadWorkorderMissingPermission')}  (` + orderOrUnid + `)`,
                type: 'error',
                isClosable: true
              });
              return;
            }
          }

          throw 'Unable to download data'
        }
        catch (err) {
          toastManager.addToast({
            message: `${t('unableToDownloadWorkorder')}`,
            type: 'error',
            isClosable: true
          });
        }
      }));

      // Clean up selected items
      setItems([]);

      // Ensure a smooth transition
      setTimeout(() => {
        if (hideLoader) hideLoader();

        // Navigate back to main page
        props.setSelectedPage("OverviewPage");
      }, 400);
    }
  }

  let ShowPrivacyConsentBox = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='alert-ui gray50'>
            <h1>{t('securityInformation')}</h1>
            <p>{t('securityInformationText')}</p>
            <button className="btn btn--small btn--outline green120 mRs" onClick={() => {
              acceptPrivacyWarningClick();
              onClose();
              SynchronizeClick();
            }}>{t('accept')}</button>&nbsp;&nbsp;&nbsp;
            <button className="btn btn--small btn--outline gray50 mRs" onClick={onClose}>{t('cancel')}</button>
          </div>
        )
      }
    })
  };

  function acceptPrivacyWarningClick() {
    let timestamp = Math.floor(new Date().getTime() / 1000).toString();
    let obj = new UserWithAcceptedPrivacyWarning(username, timestamp);
    let newArray: Array<UserWithAcceptedPrivacyWarning> = getCurrentPrivacyNoticeAccepted();

    // Checks what type of operation should be done on 'privacyNoticeAccepted' in Local Storage:
    if (timestampExistsOnUser) {
      let newArrayUserRemoved: Array<UserWithAcceptedPrivacyWarning> = [];
      newArrayUserRemoved = newArray.filter(user => user.username !== username)
      newArrayUserRemoved.push(obj);
      newArray = newArrayUserRemoved;
    } else if (!timestampExistsOnUser && newArray.length > 0) {
      newArray.push(obj);
    } else if (newArray.length === 0) {
      newArray = new Array<UserWithAcceptedPrivacyWarning>();
      newArray.push(obj);
    } else {
      newArray = new Array<UserWithAcceptedPrivacyWarning>();
      newArray.push(obj);
    }

    // Either way, the old 'privacyNoticeAccepted' in Local Storage is replaced with 'newArray':
    localStorage.removeItem("privacyNoticeAccepted");
    localStorage.setItem("privacyNoticeAccepted", JSON.stringify(newArray));
  }

  function DeleteClick() {
    if (getItems && setItems) {
      let selectedWorkorders = getItems();

      if (selectedWorkorders.length === 0) {
        props.setSelectedPage("OverviewPage");
      }

      selectedWorkorders.forEach(workorderId => {
        DatabaseService.getInstance().removeFromStoredWorkordersAsync(workorderId.unid).then(res => {
          setItems([]);

          props.setSelectedPage("OverviewPage");
        });
      });
    }
  }

  let ShowDeleteConfirmBox = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='alert-ui gray50'>
            <h1>{t('deleteAO')}</h1>
            <p>{t('deleteAOText')}</p>
            <button className="btn btn--small btn--outline red120 mRs" onClick={() => {
              DeleteClick()
              onClose()
            }}>{t('yes')}</button>&nbsp;&nbsp;&nbsp;
            <button className="btn btn--small btn--outline gray50 mRs" onClick={onClose}>{t('no')}</button>
          </div>
        )
      }
    })
  };

  if (selectedPage == "OverviewPage") {
    return (<>
      <footer className="fill-primary100 w100">
        <div className="wrap portalAppHeaderMargin">
          <div className="grid grid--justify">
            <div style={{ marginTop: "5px", width: "50%" }} className="grid-cell" onClick={() => {
              props.setSelectedPage("WorkorderListPage");
            }}>
              <button className="iconCircle iconCircle--outline white100">
                <div className="svgIcon svgIcon--medium svgIcon--downloadCloud">
                  <svg
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M12.01 12.012v9l-4-4 4 4 4-4m4.88 1.09a5 5 0 0 0-2.88-9.09h-1.26a8 8 0 1 0-13.74 7.29" />
                  </svg>
                </div>
              </button>
              &nbsp;&nbsp;{t('synchronize')}
            </div>

            <div style={{ marginTop: "5px", width: "50%", textAlign: "right" }} className="grid-cell">
              <button className="iconCircle iconCircle--outline white100" onClick={() => {
                props.setSelectedPage("WorkorderDeletePage");
              }}>
                <div className="svgIcon svgIcon--medium svgIcon--thrash">
                  <svg
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M3 6h18-2v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6h3V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2m-6 5v6m4 0v-6" />
                  </svg>
                </div>
              </button>
            </div>
          </div>
        </div>
      </footer>
    </>)
  } else if (selectedPage == "WorkorderListPage") {
    return (<>
      <footer className="fill-primary100 w100">
        <div className="wrap portalAppHeaderMargin">
          <div style={{ marginTop: "5px", width: "50%" }} className="grid-cell w100" onClick={SynchronizeClick}>
            <button className="iconCircle iconCircle--outline white100">
              <div className="svgIcon svgIcon--medium svgIcon--downloadCloud">
                <svg
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M12.01 12.012v9l-4-4 4 4 4-4m4.88 1.09a5 5 0 0 0-2.88-9.09h-1.26a8 8 0 1 0-13.74 7.29" />
                </svg>
              </div>
            </button>
            &nbsp;&nbsp;{t('download')}
          </div>
        </div>
      </footer>
    </>)
  } else if (selectedPage == "WorkorderDeletePage") {
    return (<>
      <footer className="fill-primary100 w100">
        <div className="wrap portalAppHeaderMargin">
          <div style={{ marginTop: "5px", width: "50%" }} className="grid-cell w20" onClick={ShowDeleteConfirmBox}>
            <button className="iconCircle iconCircle--outline white100">
              <div className="svgIcon svgIcon--medium svgIcon--thrash">
                <svg
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M3 6h18-2v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6h3V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2m-6 5v6m4 0v-6" />
                </svg>
              </div>
            </button>
          </div>
        </div>
      </footer>
    </>)
  } else {
    return (<div></div>)
  }
}

export class UserWithAcceptedPrivacyWarning {
  readonly username: string;
  readonly timestamp: string;

  constructor(username: string, timestamp: string) {
    this.username = username;
    this.timestamp = timestamp;
  }
}

export default Footer;