import { createContext, useState } from 'react';
import { Spinner } from '@volue/wave-react';

interface ILoaderOverlayContext {
    showLoader?: () => void;
    hideLoader?: () => void;
}

const LoaderOverlayDefaultState = {
};

const LoaderOverlayContext = createContext<ILoaderOverlayContext>(LoaderOverlayDefaultState);

const LoaderOverlayProvider = ({ children }: { children: any }) => {

    const [displayState, setDisplayState] = useState('none');

    function show() {
        setDisplayState('block');
    }

    function hide() {
        setDisplayState('none');
    }

    return (
        <LoaderOverlayContext.Provider value={{ showLoader: show, hideLoader: hide }}>
            <div className="overlay overlay--fixed overlay--is-shown" style={{ "display": `${displayState}`, "zIndex": 900, "textAlign": "center" }}>
                <Spinner style={{ "zIndex": 901, "position": "absolute", "top": "50%", "marginLeft": "-32px" }} />
            </div>

            {children}
        </LoaderOverlayContext.Provider>
    )
}

export { LoaderOverlayContext, LoaderOverlayProvider };