import { useEffect, useState } from 'react';
import ErrorMessage from '../models/errorMessage';
import { useTranslation } from 'react-i18next';
import '../i18n/config';
import HttpService from "../services/http";
import SessionService from '../services/session';
import { getUsername } from './LoginPage';

function ChangePasswordPage(props: any) {

    const { t } = useTranslation();
    let [errorMessage, setErrorMessage] = useState<ErrorMessage | undefined>(undefined);

    let [state, setState] = useState({
        oldPassword: '',
        newPassword: '',
        newPasswordRepeat: '',
        passwordIsNineCharacters: false,
        passwordContainsLowerCase: false,
        passwordContainsUpperCase: false,
        passwordContainsNumber: false,
        passwordContainsSpecialCharacter: false,
        newPasswordIsDifferentThanOldPassword: false,
        repeatedPasswordEqualsNewPassword: false,
    });

    let handleInputChange = (event: any) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    var lowerCaseCheck = /[a-zæøå]/;
    var upperCaseCheck = /[A-ZÆØÅ]/;
    var numberCaseCheck = /[0123456789]/;
    var specialCharacterCheck = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

    useEffect(() => {
        //Password is nine characters:
        if (state.passwordIsNineCharacters === false) {
            if (state.newPassword.length > 8) {
                setState({ ...state, passwordIsNineCharacters: true });
            }
        } else {
            if (state.newPassword.length <= 8) {
                setState({ ...state, passwordIsNineCharacters: false });
            }
        }
        //Contains lower case:
        if (state.passwordContainsLowerCase === false) {
            if (lowerCaseCheck.test(state.newPassword)) {
                setState({ ...state, passwordContainsLowerCase: true });
            }
        } else {
            if (lowerCaseCheck.test(state.newPassword) !== true) {
                setState({ ...state, passwordContainsLowerCase: false });
            }
        }
        //Contains upper case:
        if (state.passwordContainsUpperCase === false) {
            if (upperCaseCheck.test(state.newPassword)) {
                setState({ ...state, passwordContainsUpperCase: true });
            }
        } else {
            if (upperCaseCheck.test(state.newPassword) !== true) {
                setState({ ...state, passwordContainsUpperCase: false });
            }
        }
        //Contains number:
        if (state.passwordContainsNumber === false) {
            if (numberCaseCheck.test(state.newPassword)) {
                setState({ ...state, passwordContainsNumber: true });
            }
        } else {
            if (numberCaseCheck.test(state.newPassword) !== true) {
                setState({ ...state, passwordContainsNumber: false });
            }
        }
        //Contains special character:
        if (state.passwordContainsSpecialCharacter === false) {
            if (specialCharacterCheck.test(state.newPassword)) {
                setState({ ...state, passwordContainsSpecialCharacter: true });
            }
        } else {
            if (specialCharacterCheck.test(state.newPassword) !== true) {
                setState({ ...state, passwordContainsSpecialCharacter: false });
            }
        }
        //New and old password is different:
        if (state.newPasswordIsDifferentThanOldPassword === false) {
            if (state.oldPassword !== "" && state.newPassword !== "" && state.newPassword !== state.oldPassword) {
                setState({ ...state, newPasswordIsDifferentThanOldPassword: true });
            }
        } else {
            if (state.oldPassword !== "" && state.newPassword !== "" && state.newPassword === state.oldPassword) {
                setState({ ...state, newPasswordIsDifferentThanOldPassword: false });
            } else if (state.oldPassword === "" || state.newPassword === "") {
                setState({ ...state, newPasswordIsDifferentThanOldPassword: false });
            }
        }
        //Repeated new password equals new password:
        if (state.newPasswordIsDifferentThanOldPassword) {
            if (state.repeatedPasswordEqualsNewPassword === false) {
                if (state.newPasswordRepeat !== "" && state.newPassword !== "") {
                    if (state.newPasswordRepeat === state.newPassword) {
                        setState({ ...state, repeatedPasswordEqualsNewPassword: true });
                    }
                }
            } else {
                if (state.newPasswordRepeat !== state.newPassword) {
                    setState({ ...state, repeatedPasswordEqualsNewPassword: false });
                }
            }
        }
    }, [state])

    const ValidationBox = (props: any) => {
        return (<>
            <div className="mTn">
                <label className="dB accent100">
                    <h6>{props.validationText}
                        {props.validationCheck ? (
                            <span className="svgIcon svgIcon--validation fR">
                                <svg
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    stroke="green"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                >
                                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14M22 4L12 14.01l-3-3" />
                                </svg>
                            </span>
                        ) : null
                        }
                    </h6>
                </label>
            </div>
        </>
        )
    }

    function submitClick(e: any) {
        e.preventDefault();

        let spinnerIcon = document.getElementsByClassName("inlineSpinner")[0];
        if (spinnerIcon !== null && spinnerIcon != undefined) {
            spinnerIcon.setAttribute("style", "display:inherit");
            spinnerIcon.setAttribute("style", "margin-bottom:-5px");
        }
        let circularIcon = document.getElementsByClassName("iconCircle")[0];
        if (circularIcon !== null && circularIcon != undefined) {
            circularIcon.setAttribute("style", "display:none");
        }

        let allPasswordRequirementsMet = false;
        if (
            state.passwordIsNineCharacters &&
            state.passwordContainsLowerCase &&
            state.passwordContainsUpperCase &&
            state.passwordContainsNumber &&
            state.passwordContainsSpecialCharacter &&
            state.newPasswordIsDifferentThanOldPassword
        ) {
            allPasswordRequirementsMet = true;
        }

        var username: string = getUsername().toString();

        if (allPasswordRequirementsMet && state.repeatedPasswordEqualsNewPassword) {
            HttpService.getInstance().changePassword(username, state.oldPassword, state.newPassword)
                .then(res => {
                    let spinnerIcon = document.getElementsByClassName("inlineSpinner")[0];
                    if (spinnerIcon !== null && spinnerIcon != undefined) {
                        spinnerIcon.setAttribute("style", "display:none");
                    }
                    let circularIcon = document.getElementsByClassName("iconCircle")[0];
                    if (circularIcon !== null && circularIcon != undefined) {
                        circularIcon.setAttribute("style", "display:inherit");
                    }

                    if (res) {
                        if (res.status === 200) {
                            return res.json();
                        } else if (res.status === 401) {
                            setErrorMessage(new ErrorMessage(t("loginWrongUsernameOrPassword")));
                            return null;
                        } else if (res.status === 403) {
                            setErrorMessage(new ErrorMessage("Account locked"));
                            return null;
                        }
                        else {
                            setErrorMessage(new ErrorMessage("Change of password failed"));
                            return null;
                        }
                    }
                    setErrorMessage(new ErrorMessage(t("loginFailed")));
                    return null;
                })
                .then(resJson => {
                    if (resJson !== null) {
                        let token = resJson.token;
                        SessionService.getInstance().setToken(token);
                        props.setIsLoggedIn(true);
                        props.setSelectedPage('OverviewPage')
                    }

                }).catch(err => {
                    let spinnerIcon = document.getElementsByClassName("inlineSpinner")[0];
                    if (spinnerIcon !== null && spinnerIcon != undefined) {
                        spinnerIcon.setAttribute("style", "display:none");
                    }
                    let circularIcon = document.getElementsByClassName("iconCircle")[0];
                    if (circularIcon !== null && circularIcon != undefined) {
                        circularIcon.setAttribute("style", "display:inherit");
                    }
                    setErrorMessage(new ErrorMessage("Error: unable to log in"));
                });
        } else if (!allPasswordRequirementsMet) {
            setErrorMessage(new ErrorMessage("Password requirements not met"))
            let spinnerIcon = document.getElementsByClassName("inlineSpinner")[0];
            if (spinnerIcon !== null && spinnerIcon != undefined) {
                spinnerIcon.setAttribute("style", "display:none");
            }
            let circularIcon = document.getElementsByClassName("iconCircle")[0];
            if (circularIcon !== null && circularIcon != undefined) {
                circularIcon.setAttribute("style", "display:inherit");
            }
        } else if (!state.repeatedPasswordEqualsNewPassword) {
            setErrorMessage(new ErrorMessage("Repeated new password is different from new password"))
            let spinnerIcon = document.getElementsByClassName("inlineSpinner")[0];
            if (spinnerIcon !== null && spinnerIcon != undefined) {
                spinnerIcon.setAttribute("style", "display:none");
            }
            let circularIcon = document.getElementsByClassName("iconCircle")[0];
            if (circularIcon !== null && circularIcon != undefined) {
                circularIcon.setAttribute("style", "display:inherit");
            }
        }
    }

    return (<>
        <div className="section">
            <div className="grid">
                <div className="wrap portalAppHeaderMargin">
                    <h1 className="pBs bdrBs mBs app-border accent100">{t('change-password')}</h1>
                    <div className="w100 w70--ns w70--m">

                        <div className="grid-row" style={{ "display": errorMessage === undefined ? "none" : "block" }}>
                            <div className="grid-cell w100">
                                <div className="notification notification--error mBm mTm">
                                    <div>{errorMessage?.Message}</div>
                                </div>
                            </div>
                        </div>

                        <div className="grid-row">
                            <div className="grid-cell w100">
                                <label className="mTm">
                                    <span className="dB accent100">{t('old-password')}</span>
                                    <span className="formIcon w100">
                                        <i className="pf pf-lock" aria-hidden="true"></i>
                                        <input type="password" name="oldPassword" value={state.oldPassword} onChange={handleInputChange} required />
                                    </span>
                                </label>
                            </div>
                        </div>

                        <div className="grid-row">
                            <div className="grid-cell w100">
                                <ValidationBox validationText={t('validation1')} validationCheck={state.passwordIsNineCharacters} />
                                <ValidationBox validationText={t('validation2')} validationCheck={state.passwordContainsLowerCase} />
                                <ValidationBox validationText={t('validation3')} validationCheck={state.passwordContainsUpperCase} />
                                <ValidationBox validationText={t('validation4')} validationCheck={state.passwordContainsNumber} />
                                <ValidationBox validationText={t('validation5')} validationCheck={state.passwordContainsSpecialCharacter} />
                                <ValidationBox validationText={t('validation6')} validationCheck={state.newPasswordIsDifferentThanOldPassword} />
                            </div>
                        </div>

                        <div className="grid-row">
                            <div className="grid-cell w100">
                                <label>
                                    <span className="dB accent100">{t('new-password')}</span>
                                    <span className="formIcon w100">
                                        <i className="pf pf-lock" aria-hidden="true"></i>
                                        <input type="password" name="newPassword" value={state.newPassword} onChange={handleInputChange} required />
                                    </span>
                                </label>
                            </div>
                        </div>

                        <div className="grid-row">
                            <div className="grid-cell w100">
                                <label>
                                    <span className="dB accent100">{t('repeatOnceMore')}</span>
                                    <div className="formIcon w100">
                                        <i className="pf pf-lock" aria-hidden="true"></i>
                                        <input type="password" name="newPasswordRepeat" value={state.newPasswordRepeat} onChange={handleInputChange} required />
                                            
                                            <span 
                                                className="svgIcon svgIcon--validation fR" 
                                                style={{ display: state.repeatedPasswordEqualsNewPassword ? "inline-block" : "none"}}>
                                                <svg
                                                    viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    stroke="green"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                >
                                                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14M22 4L12 14.01l-3-3" />
                                                </svg>
                                            </span>
                                    </div>
                                </label>
                            </div>
                        </div>

                        <div className="grid-row">
                            <div className="grid-cell w100">
                                <div className="mTl">
                                    <button type="button" onClick={submitClick} className="btn btn--small btn--narrow fill-navy100">{t('change-password-btn')}
                                        <span className="inlineSpinner inlineSpinner--medium white mLs" style={{ display: "none", fontSize: "1.8rem" }} ></span>
                                        <span className="mLs iconCircle iconCircle--outline" style={{ marginTop: "-5px !important" }}><i className="pf pf-arrow-right"></i></span>
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div >
                </div >
            </div >
        </div >
        <div className="fs0 pHl pVm zeta wsNW txtR">
            <a href="https://www.volue.com/privacy-statement" target="_new">{t('privacyStatement')}</a>&nbsp; &copy; 2022 Volue AS. All Rights Reserved.
        </div>
    </>
    )
}

export default ChangePasswordPage;