import WorkorderIcon from "./workorderIcon";

class WorkorderListElementModel {

    public workorderId: string = ''
    public title: string = '';
    public appartmentInfo: string = '';
    public address: string = '';
    public label: string = '';
    public date: Date = new Date("0000-01-01");
    public icon: WorkorderIcon = WorkorderIcon.NotDefined;
    public orderReference: string = '';
    public NmReference: string = '';
    public meterNumberNew: string = '';
    public meterNumber: string = '';
    public MalerType: string = '';

    constructor() {  }
}

export default WorkorderListElementModel;