import { useState } from "react";
import ErrorMessage from "../models/errorMessage";
import HttpService from "../services/http";
import SessionService from "../services/session";
import '../i18n/config';
import { useTranslation } from 'react-i18next';
import ApplicationVersion from "../version";

var usernameExport: string;

function LoginPage(props: any) {
    const { t } = useTranslation();

    let [errorMessage, setErrorMessage] = useState<ErrorMessage | undefined>(undefined)

    let [state, setState] = useState({
        username: '',
        password: ''
    });

    let handleInputChange = (event: any) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    function loginClick(e: any) {
        e.preventDefault();

        let spinnerIcon = document.getElementsByClassName("inlineSpinner")[0];
        if (spinnerIcon !== null && spinnerIcon != undefined) {
            spinnerIcon.setAttribute("style", "display:inherit");
            spinnerIcon.setAttribute("style", "margin-bottom:-5px");
        }
        let circularIcon = document.getElementsByClassName("iconCircle")[0];
        if (circularIcon !== null && circularIcon != undefined) {
            circularIcon.setAttribute("style", "display:none");
        }

        HttpService.getInstance().login(state.username, state.password)
            .then(res => {
                let spinnerIcon = document.getElementsByClassName("inlineSpinner")[0];
                if (spinnerIcon !== null && spinnerIcon != undefined) {
                    spinnerIcon.setAttribute("style", "display:none");
                }
                let circularIcon = document.getElementsByClassName("iconCircle")[0];
                if (circularIcon !== null && circularIcon != undefined) {
                    circularIcon.setAttribute("style", "display:inherit");
                }
                if (res) {
                    if (res.status === 200) {
                        return res.json();
                    } else if (res.status === 401) {
                        setErrorMessage(new ErrorMessage(t("loginWrongUsernameOrPassword")));
                        return null;
                    } else if (res.status === 403) {
                        res.text().then((body: string) => {
                            if (body == "locked out") {
                                setErrorMessage(new ErrorMessage(t("loginLockedOut")));
                            }
                            else {
                                usernameExport = state.username;
                                props.setSelectedPage('ChangePasswordPage');
                            }
                        });
                        return null;
                    }
                }
                setErrorMessage(new ErrorMessage(t("loginFailed")));
                return null;
            })
            .then(res => {
                if (res !== null) {
                    let token = res.token;
                    SessionService.getInstance().setToken(token);
                    props.setIsLoggedIn(true);
                    props.setSelectedPage('OverviewPage')
                }

            }).catch(err => {
                let spinnerIcon = document.getElementsByClassName("inlineSpinner")[0];
                if (spinnerIcon !== null && spinnerIcon != undefined) {
                    spinnerIcon.setAttribute("style", "display:none");
                }
                let circularIcon = document.getElementsByClassName("iconCircle")[0];
                if (circularIcon !== null && circularIcon != undefined) {
                    circularIcon.setAttribute("style", "display:inherit");
                }
                setErrorMessage(new ErrorMessage("Error: unable to log in"));
            });
    }

    function forgetClick(e: any) {
        e.preventDefault();

        let windowLocation = window.location.href;
        let forgotPasswordUrl = '';

        if (windowLocation.includes('localhost')) {                                             // local development, goes to demo env
            forgotPasswordUrl = 'https://demo.elsmart.com/portal.nsf/frmLoginHelp?OpenForm';
        } else if (windowLocation.includes('wodemo.elsmart.com')) {                             // demo
            forgotPasswordUrl = 'https://demo.elsmart.com/portal.nsf/frmLoginHelp?OpenForm';
        } else if (windowLocation.includes('wodemose.elsmart.com')) {                           // demose
            forgotPasswordUrl = 'https://demose.elsmart.com/portal.nsf/frmLoginHelp?OpenForm';
        } else if (windowLocation.includes('wose.elsmart.com')) {                               // se
            forgotPasswordUrl = 'https://se.elsmart.com/portal.nsf/frmLoginHelp?OpenForm';
        } else {                                                                                // production (wo.elsmart.com)
            forgotPasswordUrl = 'https://www.elsmart.com/portal.nsf/frmLoginHelp?OpenForm';
        }

        window.open(forgotPasswordUrl, '_blank');
    }

    return (<>
        <div className="section">
            <div className="grid">
                <div className="wrap portalAppHeaderMargin">
                    <h1 className="pBs bdrBs mBs app-border accent100">{t('login')}</h1>
                    <div className="w100 w70--ns w70--m">

                        <div className="grid-row" style={{ "display": errorMessage === undefined ? "none" : "block" }}>
                            <div className="grid-cell w100">
                                <div className="notification notification--error mBm mTm">
                                    <div>{errorMessage?.Message}</div>
                                </div>
                            </div>
                        </div>

                        <div className="grid-row">
                            <div className="grid-cell w100">
                                <label className="mTm">
                                    <span className="dB accent100">{t('username')}</span>
                                    <span className="formIcon w100">
                                        <i className="pf pf-user" aria-hidden="true"></i>
                                        <input type="text" name="username" value={state.username} onChange={handleInputChange} />
                                    </span>
                                </label>
                            </div>
                        </div>


                        <div className="grid-row">
                            <div className="grid-cell w100">
                                <label>
                                    <span className="dB accent100">{t('password')}</span>
                                    <span className="formIcon w100">
                                        <i className="pf pf-lock" aria-hidden="true"></i>
                                        <input type="password" name="password" value={state.password} onChange={handleInputChange} />
                                    </span>
                                </label>
                            </div>
                        </div>

                        <div className="grid-row">
                            <div className="grid-cell w100">
                                <div className="mTl">
                                    <button type="button" onClick={loginClick} className="btn btn--small btn--narrow fill-navy100">{t('login')}
                                        <span className="inlineSpinner inlineSpinner--medium white mLs" style={{ display: "none", fontSize: "1.8rem" }} ></span>
                                        <span className="mLs iconCircle iconCircle--outline" style={{ marginTop: "-5px !important" }}><i className="pf pf-arrow-right"></i></span>
                                    </button>
                                    <button className="fR mBs accent100" style={{ cursor: "pointer !important" }} onClick={forgetClick}>{t('forgot-password')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="fs0 pHl pVm zeta wsNW txtR">
            <a href="https://www.volue.com/privacy-statement" target="_new">{t('privacyStatement')}</a>&nbsp; &copy; 2022 Volue AS
            <ApplicationVersion />
        </div>
    </>
    );
}

export function getUsername() { return usernameExport; }

export default LoginPage;
