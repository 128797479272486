import { useEffect, useState } from "react";
import WorkorderService from "../services/workorder";
import '../i18n/config';
import { useTranslation } from 'react-i18next';
import Dependents from "../helpers/Dependents";

function YesNoQuestion(props: any) {
    const { t } = useTranslation();

    let [init, setInit] = useState(false);
    const [selection, setSelection] = useState("0");

    let radioName = `${props.question.id}-radio`;

    let onYesChange = (event: any) => {
        change(true);
    }

    let onNoChange = (event: any) => {
        change(false);
    }

    function change(val: boolean) {
        let valStr = val ? "1" : "2";

        setSelection(valStr);

        WorkorderService.getInstance().UpdateAnswer(props.question.id, props.question.itemType, val);
        Dependents.handleDependents(props.question, val);
    }

    function LabelText() {
        if (props.question.isMandatory == true) {
            return (<>
                <label className="black">{props.question.label}</label><span style={{ color: "red" }}>*</span>
            </>)
        } else {
            return (
                <label className="black">{props.question.label}</label>
            )
        }
    }

    useEffect(() => {
        if (!init) {
            setInit(true);

            Dependents.handleDependents(props.question);
        }

        if (props.question.answer !== null) {
            let val = props.question.answer.yesNo;

            if (val === true) {
                setSelection("1");
            } else if (val === false) {
                setSelection("2");
            } else {
                setSelection("0");
            }
        } else if (props.question.defaultValue !== null) {
            let val = props.question.defaultValue.yesNo;

            if (val === true) {
                setSelection("1");
            }
        }
    }, []);

    return (
        <div className="mTs measure" style={{ "marginBottom": "15px" }}>
            <LabelText />

            <ul className="bareList">
                <li>
                    <input type="radio" name={radioName} id={`${radioName}-1`} checked={selection === "1"} onChange={onYesChange} />
                    <label className="btn btn--outline accent120 w50" htmlFor={`${radioName}-1`}>{t('yes')}</label>
                </li>
                <li>
                    <input type="radio" name={radioName} id={`${radioName}-2`} checked={selection === "2"} onChange={onNoChange} />
                    <label className="btn btn--outline accent120 w50" htmlFor={`${radioName}-2`}>{t('no')}</label>
                </li>
            </ul>
        </div>
    )
}

export default YesNoQuestion;