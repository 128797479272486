import { useEffect, useState } from "react";
import WorkorderService from "../services/workorder";

function TextAreaQuestion(props: any) {
    let [init, setInit] = useState(false);
    let [isInvalid, setIsInvalid] = useState(false);
    let [state, setState] = useState({
        value: ''
    });

    let handleInputChange = (event: any) => {
        let targetName = event.target.name;
        let targetValue = event.target.value;
        let updatedAnswer = targetValue;

        setState({ ...state, [targetName]: targetValue });

        WorkorderService.getInstance().UpdateAnswer(props.question.id, props.question.questionType, updatedAnswer);
    };

    function LabelText() {
        if (props.question.isMandatory == true) {
            return (<>
                <label className="black">{props.question.label}</label><span style={{ color: "red" }}>*</span>
            </>)
        } else {
            return (
                <label className="black">{props.question.label}</label>
            )
        }
    }

    useEffect(() => {
        setIsInvalid(props.question.isMandatory && state.value === "");

        if (init === false) {
            setInit(true);

            if (props.question.answer !== null) {
                setState({ value: props.question.answer.text ?? "" });
            } else {
                setState({ value: props.question.defaultValue.text ?? "" });
            }
        }
    });

    return (<>
        <LabelText /><br></br>
        <textarea name="value" disabled={props.question.isReadOnly} value={state.value} className={`grid-cell w100 w80--m ${isInvalid ? "is-invalid" : ""}`} onChange={handleInputChange} />
    </>)
}

export default TextAreaQuestion;
