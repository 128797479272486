enum WorkorderIcon {

    NewMeter = 0,
    MeterChange = 1,
    MeterDisassembly = 2,
    MeterSealing = 3,
    NotDefined = 5

    // TODO: add new icon types as needed. Also add them to the renderer!
}

export default WorkorderIcon;