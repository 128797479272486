import ChangePasswordUser from "../models/changePasswordUser";
import LoginUser from "../models/loginUser";
import SessionService from "./session";

class HttpService {
    private static instance: HttpService;
    private baseUrl: string = "";
    private loginUser: LoginUser = new LoginUser;
    private changePasswordUser : ChangePasswordUser = new ChangePasswordUser;

    static getInstance(): HttpService {
        if (!HttpService.instance) {
            HttpService.instance = new HttpService();
        }

        return HttpService.instance;
    }

    private constructor() {
        let url = window.location.href;

        if (url.indexOf('localhost') > 0) {
            this.baseUrl = "https://localhost:44335";
        } else if (url.indexOf('-dev') > 0) {
            this.baseUrl = "https://app-elsmart-workorderapp-api-dev.azurewebsites.net";
        } else if (url.indexOf('-prod') > 0) {
            this.baseUrl = "https://app-elsmart-workorder-api-prod.azurewebsites.net/";
        } else if (url.indexOf('wo.elsmart.com') > 0) {
            this.baseUrl = "https://app-elsmart-workorder-api-prod.azurewebsites.net/";
        } else {
            console.error("Unable to find API");
        }
    }

    // The langauge setting on web uses different values than the API uses. See ElsmartLanguageValue in API.
    convertWebLanguageToApiLanguage(webLanguage: string): string {
        if (webLanguage == "en") {
            return "en-us";
        }
        else if (webLanguage == "se") {
            return "sv-se";
        }

        // Default to Norwegian
        return "no-no";
    }

    async query(method: string, path: string): Promise<Response | undefined> {
        if (this.baseUrl == "") {
            console.error("Unable send requests, no API found");
            return;
        }

        let token = SessionService.getInstance().getToken();

        let requestOptions = {
            method: method,
            cache: 'no-cache' as RequestCache,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };

        var requestUrl = `${this.baseUrl}/${path}`;
        var result;

        try {
            await fetch(requestUrl, requestOptions).then((res) => {
                result = res;
            }).catch((error) => {
                console.log("Error in fetch: " + error);
            })
        } catch (error) {
            console.log("Error in fetch: " + error);
        }

        return result;
    }

    async queryWithData(method: string, path: string, data: string): Promise<Response | undefined> {
        if (this.baseUrl == "") {
            console.error("Unable send requests, no API found");
            return;
        }

        let token = SessionService.getInstance().getToken();

        let requestOptions = {
            method: method,
            cache: 'no-cache' as RequestCache,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: data
        };

        var requestUrl = `${this.baseUrl}/${path}`;

        var result;

        await fetch(requestUrl, requestOptions).then((res) => {
            result = res;
        }).catch((error) => {
            console.log("Error in fetch: " + error);
        })

        return result;
    }

    login(username: string, password: string): Promise<Response | undefined> {
        this.loginUser.Username = username;
        this.loginUser.Password = password;

        return this.queryWithData('POST', 'api/Auth/login', JSON.stringify(this.loginUser));
    }

    logout(): Promise<any> {
        return this.query('GET', 'api/Auth/logout');
    }

    changePassword(username : string, oldPassword: string, newPassword: string): Promise<any> {
        this.changePasswordUser.Username = username;
        this.changePasswordUser.OldPassword = oldPassword;
        this.changePasswordUser.NewPassword = newPassword;
        
        return this.queryWithData('POST', 'api/Auth/changepassword', JSON.stringify(this.changePasswordUser));
    }

    getWorkorders(): Promise<any> {
        return this.query('GET', 'api/Workorder/list');
    }

    getWorkorder(gridPrefix: string, unid: string, webLanguage: string): Promise<Response | undefined> {
        let apiLanguage = this.convertWebLanguageToApiLanguage(webLanguage);

        return this.query('GET', `api/Workorder/${gridPrefix}/${unid}/${apiLanguage}`);
    }

    async updateWorkorder(workorder: any): Promise<any> {
        return await this.queryWithData('PUT', `api/Workorder`, JSON.stringify(workorder));
    }

    async cannotBeStarted(workorder: any): Promise<any> {
        return await this.queryWithData('POST', 'api/Workorder/cannotbestarted', JSON.stringify(workorder));
    }

    async safeJobAnalysisFailed(workorder: any): Promise<any> {
        return await this.queryWithData('POST', 'api/Workorder/safejobanalysisfailed', JSON.stringify(workorder));
    }

    async facilityPowerDisconnectedFailed(workorder: any): Promise<any> {
        return await this.queryWithData('POST', 'api/Workorder/facilitypowerdisconnectedfailed', JSON.stringify(workorder));
    }

    async wrongExistingMeterNumber(workorder: any): Promise<any> {
        return await this.queryWithData('POST', 'api/Workorder/wrongexistingmeternumber', JSON.stringify(workorder));
    }

    async wrongMeterNumber(workorder: any): Promise<any> {
        return await this.queryWithData('POST', 'api/Workorder/wrongmeternumber', JSON.stringify(workorder));
    }

    async wrongTransformerNumber(workorder: any): Promise<any> {
        return await this.queryWithData('POST', 'api/Workorder/wrongtransformernumber', JSON.stringify(workorder));
    }
}

export default HttpService;