function UnknownQuestionType(props: any) {
    return (
        <div className="grid-row">
            <div className="grid-cell w100 ">{props.question.label}</div>
            <div className="grid-cell w100 red100 ">Kunne ikke lese spørsmål med id {props.question.id}. Ukjent spørsmålstype.</div>
        </div>
    );
}

export default UnknownQuestionType;
