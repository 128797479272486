import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@volue/wave-react';
import WorkorderService from "../services/workorder";
import SessionService from "../services/session";

const BarCodeWithVerificationQuestion = (props: any) => {
    const { t } = useTranslation();
    const [init, setInit] = useState(false);

    const [isInvalid, setIsInvalid] = useState(false);
    const [isInvalidRepeat, setIsInvalidRepeat] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [meterNumber, setMeterNumber] = useState<string>('');
    const [meterNumberRepeat, setMeterNumberRepeat] = useState<string>('');

    useEffect(() => {
        setIsInvalid(props.question.isMandatory && meterNumber === "");
        setIsInvalidRepeat(props.question.isMandatory && meterNumberRepeat === "");

        if (init === false) {
            setInit(true);

            let storedMeterNumber = props.question.answer?.text;
            if (storedMeterNumber == null) {
                storedMeterNumber = props.question.defaultValue?.text;
            }
            if (storedMeterNumber == null) {
                storedMeterNumber = "";
            }
            setMeterNumber(storedMeterNumber);
            setIsVerified(storedMeterNumber != "");
            setIsInvalid(props.question.isMandatory && storedMeterNumber != "");

            let barCodeQuestionId = SessionService.getInstance().getBarCodeQuestionId();
            if (barCodeQuestionId === props.question.id) {
                let newBarCodeValue = SessionService.getInstance().getBarCodeValue();
                if (newBarCodeValue !== null) {
                    handleValueChanged(newBarCodeValue, meterNumberRepeat);
                    SessionService.getInstance().removeBarCodeValue();
                }
                SessionService.getInstance().removeBarCodeQuestionId();
            }
            else if (barCodeQuestionId === props.question.id + "-repeat") {
                let newBarCodeRepeatValue = SessionService.getInstance().getBarCodeValue();
                if (newBarCodeRepeatValue !== null) {
                    handleValueChanged(storedMeterNumber, newBarCodeRepeatValue);
                    SessionService.getInstance().removeBarCodeValue();
                }
                SessionService.getInstance().removeBarCodeQuestionId();
            }
        }
    });

    let allowOnlyNumbers = (event: any) => {
        if (!event.key.match('[0-9]')) {
            event.preventDefault();
        }
    };

    function onClickValidation() {
        handleValueChanged("", "");
        WorkorderService.getInstance().UpdateAnswer(props.question.id, 0, "");
    }

    function CheckMark() {
        return (
            <div className={`svgIcon svgIcon--validation ${props.question.isReadOnly ? "gray100" : "green100"}`} onClick={props.question.isReadOnly ? undefined : onClickValidation}>
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14M22 4 12 14.01l-3-3" />
                </svg>
            </div>
        );
    }

    function handleValueChanged(meter: string, meterRepeat: string) {
        // console.log("handleValueChanged: " + meter + "+" + meterRepeat);

        setMeterNumber(meter);
        setMeterNumberRepeat(meterRepeat);
        if (meter != "" && meter === meterRepeat) {
            WorkorderService.getInstance().UpdateAnswer(props.question.id, 0, meter);
            setIsVerified(true);
        }
        else {
            setIsVerified(false);
        }
    }

    let handleInputChange = (event: any) => {
        handleValueChanged(event.target.value, meterNumberRepeat);
    }

    let handleInputChangeRepeat = (event: any) => {
        handleValueChanged(meterNumber, event.target.value);
    }

    let openScanCamera = (event: any) => {
        SessionService.getInstance().setBarCodeQuestionId(props.question.id);
        props.setScanEanCode(true);
    }

    let openScanCameraRepeat = async (event: any) => {
        WorkorderService.getInstance().UpdateAnswer(props.question.id, 0, meterNumber).then(async () => {
            // Ensure that work order is updated
            while (WorkorderService.getInstance().HasQueuedOrOngoingUpdates()) {
                await new Promise(f => setTimeout(f, 100));
            }
            SessionService.getInstance().setBarCodeQuestionId(props.question.id + "-repeat");
            props.setScanEanCode(true);
        });;
    }

    if (isVerified) {
        return (
            <div className="grid-row">
                <div className="grid-cell w40">{props.question.label}</div>
                <div className="grid-cell w40">{meterNumber}</div>
                { props.question.isReadOnly ? <></> : <CheckMark /> }
            </div>
        );
    } else if (props.question.isReadOnly) {
        return (
            <>
                <div className="grid-row">
                    <div className="grid-cell w40">{props.question.label}</div>
                    <div className="grid-cell w40">{props.question.defaultValue?.text}</div>
                </div>
            </>
        );
    }
    else {
        return (
            <>
                <div className="grid-row">
                    <div className="grid-cell w100">{props.question.label}</div>
                    <input type="text"
                        autoComplete="off"
                        style={{ "width": "calc(100% - 58px)", "margin": "4px 8px 4px 0" }}
                        className={`grid-cell ${isInvalid ? "is-invalid" : ""} ${isVerified || meterNumber === "" ? "" : "mask-on-blur"}`}
                        name="value"
                        value={meterNumber}
                        onKeyPress={allowOnlyNumbers}
                        onChange={handleInputChange} />

                    <Tooltip placement="right" content={t('scan')}>
                        <span className="iconCircle iconCircle--medium fill-accent100" onClick={openScanCamera}>
                            <div className="svgIcon svgIcon--stroked svgIcon--scanQrCode">
                                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M7.556 2H4.222A2.222 2.222 0 0 0 2 4.222v3.334m20 0V4.222A2.222 2.222 0 0 0 19.778 2h-3.334m0 20h3.334A2.222 2.222 0 0 0 22 19.778v-3.334m-20 0v3.334C2 21.005 2.995 22 4.222 22h3.334M6 18h4v-4H6m4 4H6v-4h4m-4-4h4V6H6m4 4H6V6h4m4 0h4v4h-4m4-4h-4v4h4m-4.08 8c-.11.009.119 0 .01 0h-.01m0-4c-.11.009.119 0 .01 0h-.01m4 0c-.11.009.119 0 .01 0h-.01m0 4c-.11.009.119 0 .01 0h-.01m-2-2c-.11.009.119 0 .01 0h-.01" />
                                </svg>
                            </div>
                        </span>
                    </Tooltip>
                </div>
                <div className="grid-row" style={{ "display": isVerified || meterNumber === "" ? "none" : "block" }}>
                    <div className="grid-cell w100">{t('repeatOnceMore')}</div>
                    <input type="text"
                        autoComplete="off"
                        style={{ "width": "calc(100% - 58px)", "margin": "4px 8px 4px 0" }}
                        className={`grid-cell ${isInvalidRepeat ? "is-invalid" : ""}`}
                        name="value"
                        value={meterNumberRepeat}
                        onKeyPress={allowOnlyNumbers}
                        onChange={handleInputChangeRepeat} />

                    <Tooltip placement="right" content={t('scan')}>
                        <span className="iconCircle iconCircle--medium fill-accent100" onClick={openScanCameraRepeat}>
                            <div className="svgIcon svgIcon--stroked svgIcon--scanQrCode">
                                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M7.556 2H4.222A2.222 2.222 0 0 0 2 4.222v3.334m20 0V4.222A2.222 2.222 0 0 0 19.778 2h-3.334m0 20h3.334A2.222 2.222 0 0 0 22 19.778v-3.334m-20 0v3.334C2 21.005 2.995 22 4.222 22h3.334M6 18h4v-4H6m4 4H6v-4h4m-4-4h4V6H6m4 4H6V6h4m4 0h4v4h-4m4-4h-4v4h4m-4.08 8c-.11.009.119 0 .01 0h-.01m0-4c-.11.009.119 0 .01 0h-.01m4 0c-.11.009.119 0 .01 0h-.01m0 4c-.11.009.119 0 .01 0h-.01m-2-2c-.11.009.119 0 .01 0h-.01" />
                                </svg>
                            </div>
                        </span>
                    </Tooltip>
                    <div style={{ "display": meterNumberRepeat === "" || meterNumber === meterNumberRepeat ? "none" : "block" }}>
                        <span className="red100 "> {t('differentValuesEntered')} </span>
                    </div>
                </div>
            </>
        );
    }
}

export default BarCodeWithVerificationQuestion;
