import { useEffect, useState } from "react";
import WorkorderService from "../services/workorder";

function TextInformation(props: any) {

    let [init, setInit] = useState(false);

    let [state, setState] = useState({
        label: '',
        text: ''
    });


    // let [state, setState] = useState({
    //     value: ''
    // });


    // let handleInputChange = (event: any) => {

    //     let targetName = event.target.name;
    //     let targetValue = event.target.value;
    //     let updatedAnswer = targetValue;

    //     setState({ ...state, [targetName]: targetValue });

    //     WorkorderService.getInstance().UpdateAnswer(props.question.id, props.question.questionType, updatedAnswer);
    // };

    useEffect(() => {

        if (init === false) {
            setInit(true);
            if (props.question.answer !== null) {
                setState({ text: props.question.answer.text, label: props.question.answer.label });
            } else {
                setState({ text: props.question.defaultValue.text, label: props.question.label });
            }
        }
    });

    return (
        <div className="grid-row">
            <div className="grid-cell w100 w50--m w50--l">{state.label}</div>
            <div className="grid-cell w100 w50--m w50--l">
                {
                    props.itemType ?
                        props.itemType === "tel:" ?
                            <div>
                                <div className="svgIcon svgIcon--phone mRs">
                                    <svg
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path
                                            d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72c.127.96.361 1.903.7 2.81a2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45c.907.339 1.85.573 2.81.7A2 2 0 0 1 22 16.92Z"
                                        />
                                    </svg>
                                </div>
                                <a href={props.itemType + state.text}>{state.text}</a>
                            </div>
                            :
                            <div>
                                <div className="svgIcon svgIcon--mail mRs">
                                    <svg
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path
                                            d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2M2 6l10 7 10-7"
                                        />
                                    </svg>
                                </div>
                                <a href={props.itemType + state.text}>{state.text}</a>
                            </div>
                    : state.text
                }
            </div>
        </div>
    );
}

export default TextInformation;