const ApplicationVersion = () => {
    const version = "2.1.8";

    return (
        <div>
            v{version}
        </div>
    )   
}

export default ApplicationVersion;