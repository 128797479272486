import jwt_decode from 'jwt-decode'

class SessionService {
    private static tokenNameIdentifier = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier";
    private static instance: SessionService;

    static getInstance(): SessionService {
        if (!SessionService.instance) {
            SessionService.instance = new SessionService();
        }

        return SessionService.instance;
    }

    private constructor() { }

    getToken(): string | null {
        return localStorage.getItem("token");
    }

    setToken(token: string | null): void {
        if (token === null) {
            localStorage.removeItem("token");
        } else {
            localStorage.setItem("token", token);
        }
    }

    removeToken(): void {
        localStorage.removeItem("token");
    }

    isLoggedIn(): boolean {
        return this.getToken() !== null;
    }

    getUsername(): string {
        let token = this.getToken();
        if (token) {
            const decoded = jwt_decode<any>(token);
            if (decoded) {
                return decoded[SessionService.tokenNameIdentifier] ?? "";
            }
        }

        return "";
    }

    setSelectedWorkorderId(unid: string): void {
        localStorage.setItem("selectedworkorder", unid);
    }

    getSelectedWorkorderId(): string {
        let selectedWorkorder = localStorage.getItem("selectedworkorder");

        if (selectedWorkorder === null) return "";

        return String(selectedWorkorder);
    }

    setWorkorder(workorders: any): void {
        localStorage.setItem("workorders", JSON.stringify(workorders));
    }

    setCurrentQuestionId(questionId: any): void {
        localStorage.setItem("currentQuestionId", questionId);
    }

    getCurrentQuestionId(): string | null {
        return localStorage.getItem("currentQuestionId");
    }

    removeCurrentQuestionId(): void {
        localStorage.removeItem("currentQuestionId");
    }

    setBarCodeQuestionId(questionId: string): void {
        localStorage.setItem("barCodeQuestionId", questionId);
    }

    getBarCodeQuestionId(): string | null {
        return localStorage.getItem("barCodeQuestionId");
    }

    removeBarCodeQuestionId(): void {
        localStorage.removeItem("barCodeQuestionId");
    }

    setBarCodeValue(value: string): void {
        localStorage.setItem("barCodeValue", value);
    }

    getBarCodeValue(): string | null {
        return localStorage.getItem("barCodeValue");
    }

    removeBarCodeValue(): void {
        localStorage.removeItem("barCodeValue");
    }
}

export default SessionService;