import { useEffect, useState } from "react";
import { Html5Qrcode, Html5QrcodeSupportedFormats } from "html5-qrcode"
import SessionService from "../services/session";

function EanScannerComponent(props: any) {
  const numbersOnlyRegex = /^[0-9]+$/;
  let [init, setInit] = useState(false);

  useEffect(() => {
    if (!init) {
      setInit(true);

      const html5QrCode = new Html5Qrcode("video", {
        formatsToSupport: [    
          Html5QrcodeSupportedFormats.QR_CODE,
          Html5QrcodeSupportedFormats.CODE_128,
          Html5QrcodeSupportedFormats.CODE_39,
          Html5QrcodeSupportedFormats.DATA_MATRIX,
        ],
        experimentalFeatures: { useBarCodeDetectorIfSupported: true },
        verbose: false
      });
      const sourceSelect = document.getElementById('sourceSelect') as HTMLSelectElement;
      const sourceSelectChoose = document.getElementById('sourceSelectChoose') as HTMLOptionElement;

      Html5Qrcode.getCameras().then(devices => {
        if (devices && devices.length > 1) {
          devices.forEach((device) => {
            const sourceOption = document.createElement('option');
            sourceOption.text = device.label;
            sourceOption.value = device.id;
            sourceSelect.appendChild(sourceOption);
          });
          sourceSelect.onchange = () => {
            sourceSelect.disabled = true;
            localStorage.setItem("selectedCamera", sourceSelect.value);
            if (sourceSelect.contains(sourceSelectChoose)) {
              sourceSelect.removeChild(sourceSelectChoose);
            }
            html5QrCode.stop();
            initScanner(html5QrCode, sourceSelect.value).then(()=>{
              sourceSelect.disabled = false;
            });
          };
          sourceSelect.style.display = 'block';
        }
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        sourceSelect.disabled = true;
        let selectedCamera = localStorage.getItem("selectedCamera");
        if (selectedCamera != null) {
          try {
            initScanner(html5QrCode, selectedCamera).then(()=>{
              if (sourceSelect.contains(sourceSelectChoose)) {
                sourceSelect.removeChild(sourceSelectChoose);
              }
              sourceSelect.value = selectedCamera ?? "";
              sourceSelect.disabled = false;
            });
          }
          catch {
            initScanner(html5QrCode, { facingMode: "environment" }).then(()=>{
              sourceSelect.disabled = false;
            });
          }
        } else {
          initScanner(html5QrCode, { facingMode: "environment" }).then(()=>{
            sourceSelect.disabled = false;
          });
        }
      });

      document.getElementById('back')?.addEventListener('click', () => {
        try {
          html5QrCode.stop();
        } catch (error) {
          console.error(error);
        }
        props.setScanEanCode(false);
      });
    }
  }, [init]);

  async function initScanner(html5QrCode: Html5Qrcode, cameraIdOrConfig: string | MediaTrackConstraints): Promise<void | null> {
    return html5QrCode.start(cameraIdOrConfig,
      {
        fps: 10,
        qrbox: qrboxFunction
      },
      (decodedText: string, result: any) => {
        let barCode = decodedText;
        if (barCode.startsWith("]C1")) { // Remove prefix used by barcode standard GS1-128
          barCode = barCode.substring(3);
        }

        if (!barCode || barCode.match(numbersOnlyRegex) == null) {
          console.log("Invalid code scanned: '" + barCode + "'");
          return;
        };

        SessionService.getInstance().setBarCodeValue(barCode);
        html5QrCode.stop();
        props.setScanEanCode(false);
      }, (error: any) => {
        // nothing found on this try
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function qrboxFunction (viewfinderWidth: number, viewfinderHeight: number) {
    // Square QR Box, with size = 80% of the min edge.
    let minEdgeSizeThreshold = 250;
    let edgeSizePercentage = 0.8;

    let minEdgeSize = (viewfinderWidth > viewfinderHeight) ? viewfinderHeight : viewfinderWidth;
    let qrboxEdgeSize = Math.floor(minEdgeSize * edgeSizePercentage);
    if (qrboxEdgeSize < minEdgeSizeThreshold) {
      if (minEdgeSize < minEdgeSizeThreshold) {
        return { width: minEdgeSize, height: minEdgeSize };
      } else {
        return {
          width: minEdgeSizeThreshold,
          height: minEdgeSizeThreshold
        };
      }
    }
    return { width: qrboxEdgeSize, height: qrboxEdgeSize };
  }

  return (
    <>
      <div style={{ display: "flex" }}>
        <div style={{ width: "100%" }}>
          <select id="sourceSelect" style={{ margin: "5px", display: "none" }}>
            <option id="sourceSelectChoose">Velg kamera</option>
          </select>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <button style={{ marginLeft: "10px" }} type="button" className="btn btn--outline accent120" id="back">
            <span className="svgIcon svgIcon--stroked mRs">
              <svg
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M10.667 6L4 12l6.667 6M20 18l-6.667-6L20 6" />
              </svg>
            </span>
            Avbryt
          </button>
        </div>
      </div>
      <div id="video" style={{ border: "1px solid gray", width: "100%" }}></div>
      <style>
        {`#content { padding: 56px 0px 0px 0px!important; }`}
      </style>
    </>
  )
}

export default EanScannerComponent;
