import { useEffect, useState, useContext } from 'react';
import './App.css';
import AboutPage from './pages/AboutPage';
import SettingsPage from './pages/SettingsPage';
import LoginPage from './pages/LoginPage';
import OverviewPage from './pages/OverviewPage';
import WorkorderPage from './pages/WorkorderPage';
import WorkorderListPage from './pages/WorkorderListPage';
import WorkorderDeletePage from './pages/WorkorderDeletePage';
import ChangePasswordPage from './pages/ChangePasswordPage';
import SessionService from './services/session';
import { createToastManager, Toaster } from '@volue/wave-react';
import 'react-confirm-alert/src/react-confirm-alert.css';

import './i18n/config';
import { useTranslation } from 'react-i18next';
import { WorkorderListProvider } from './context/WorkorderListContextProvider';
import Footer from './components/Footer';
import { LoaderOverlayProvider } from './context/LoaderOverlay';
import HttpService from "./services/http";
import { LoaderOverlayContext } from './context/LoaderOverlay';

const toastManager = createToastManager();

setTimeout(() => {
  require('@volue/wave').default();
}, 100);

function LoginButton(props: any) {
  const { t } = useTranslation();

  return (
    <li>
      <a onClick={function () {
        props.setIsLoggedIn(false);
        props.setSelectedPage('LoginPage');

        return false;
      }}>{t('login')}</a>
    </li>
  )
}

function LogoutButton(props: any) {
  const { t } = useTranslation();
  const { hideLoader, showLoader } = useContext(LoaderOverlayContext);

  return (
    <li><a onClick={function () {
      if(showLoader) { showLoader(); }
      HttpService.getInstance().logout().then(() => {
        SessionService.getInstance().removeToken();
        props.setSelectedPage('LoginPage');
        if(hideLoader) { hideLoader(); }
        props.setIsLoggedIn(true);
      });

      return false;
    }}>{t('logout')}</a></li>
  )
}

function SelectedPage(props: any) {

  switch (props.selectedPage) {
    case "LoginPage":
      return <LoginPage setIsLoggedIn={props.setIsLoggedIn} setSelectedPage={props.setSelectedPage} />
    case "ChangePasswordPage":
      return <ChangePasswordPage setIsLoggedIn={props.setIsLoggedIn} setSelectedPage={props.setSelectedPage} />
    case "AboutPage":
      return <AboutPage />
    case "SettingsPage":
      return <SettingsPage />
    case "OverviewPage":
      return <OverviewPage setSelectedPage={props.setSelectedPage} />
    case "WorkorderPage":
      return <WorkorderPage setIsLoggedIn={props.setIsLoggedIn} setSelectedPage={props.setSelectedPage} />
    case "WorkorderListPage":
      return <WorkorderListPage setSelectedPage={props.setSelectedPage} setIsLoggedIn={props.setIsLoggedIn} />
    case "WorkorderDeletePage":
      return <WorkorderDeletePage setSelectedPage={props.setSelectedPage} />
    default:
      if (SessionService.getInstance().isLoggedIn()) return <OverviewPage setSelectedPage={props.setSelectedPage} />
      return <LoginPage />
  }
}

function App() {
  const { t } = useTranslation();

  let [selectedPage, setSelectedPage] = useState("LoginPage");
  let [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {

    setIsLoggedIn(SessionService.getInstance().isLoggedIn());

    if (selectedPage === "LoginPage" && SessionService.getInstance().isLoggedIn()) {
      setSelectedPage('OverviewPage');
    }
  }, [selectedPage]);

  return (
    <>
      <Toaster manager={toastManager}>
        <LoaderOverlayProvider>
          <WorkorderListProvider>

            <header className="fixed fill-primary100 w100 docsHeader cf">
              <a href="./" aria-label="Elsmart Arbeidsordre, tilbake til forside" className="posA maw100 mihI dF aiC pHm mRxl linkClean">
                <div className="fs0 is-visibleLarge mRs">
                  <svg role="img" className="vaT" focusable="false" xmlns="http://www.w3.org/2000/svg" width="121" height="56" viewBox="0 0 242 112">
                    <title>Volue logo</title>
                    <path fill="currentColor" d="M34.0590203 68.0678295c1.3699097 4.2562492 1.217761 4.6363869 1.217761 6.8405771h2.7400097c0-2.2041902-.1521487-2.6603935 1.217761-6.8405771l9.2090911-28.8819082h6.8497388l-12.481907 38.0025508H30.481907L18 39.1859213h6.84973877l9.20928153 28.8819082M147.88149 60.6192984c0 8.740695 4.718705 12.3888 11.416295 12.3888 7.839181 0 11.416485-4.6360066 11.416485-13.3767017V39.1859213h6.088805v38.0025508h-6.088805v-5.6242885h-2.74001c-1.978885 5.396282-6.925718 6.6884459-10.807319 6.6884459-10.351063 0-15.374065-6.6884459-15.374065-17.6333311V39.1859213h6.088614v21.4333771zM204.886033 43.2902295c-6.545442 0-10.959849 4.2562492-11.873122 11.2485771h23.898202c-.914035-6.9923279-5.328062-11.2485771-12.02508-11.2485771zm0-5.1684656c12.329758 0 18.341441 8.588564 18.341441 19.4573836v1.6721115h-30.442691c.228128 8.436623 4.794493 13.9087803 12.10125 13.9087803 5.632549 0 9.284879-3.1160262 11.187214-8.2844918h6.394436c-2.130844 7.8286689-7.687414 13.377082-17.58165 13.377082-12.10125 0-18.190245-8.5887541-18.190245-19.5334492v-1.1400328c0-10.8688196 6.088995-19.4573836 18.190245-19.4573836zM126.271422 24.08481311h-6.088805V71.792c0 2.9642754 2.435522 5.3964721 5.40385 5.3964721h6.830506v-5.9283606h-6.145551V24.08481311M78.5005353 76.4025246h13.3218669l10.2050082-8.5516721 2.3130794-13.1005836-6.6608383-11.5216525-12.5180876-4.5496721-12.5182779 4.5496721-6.6608383 11.5216525 2.3130794 13.1005836 10.2050082 8.5516721zM93.8622232 82H76.4607143L63.1300878 70.8297705l-3.0218371-17.1143738 8.7006592-15.0491934 16.352654-5.94357379 16.3522732 5.94357379 8.7008497 15.0491934-3.0220276 17.1143738L93.8622232 82z"></path>
                  </svg>
                </div>
                <div className="fs0 is-hiddenLarge mRm">
                  <svg role="img" className="vaT w2" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                    <title>Volue logomark</title>
                    <path fill="currentColor" d="M11.746 28.365h8.508l6.517-5.553 1.477-8.508-4.253-7.482L16 3.868 8.005 6.822l-4.253 7.482 1.477 8.508 6.517 5.553zM10.443 32L1.93 24.746 0 13.632 5.557 3.86 16 0l10.443 3.86L32 13.632l-1.93 11.114L21.557 32H10.443z"></path>
                  </svg>
                </div>
                <span className="miw0 gamma wsNW txtTruncate">Elsmart Arbeidsordre</span>
              </a>

              <ul className="fR nav nav--block nav--collapse posR">
                <li><a onClick={function () { setSelectedPage('AboutPage'); return false; }}>{t('about')}</a></li>
                <li><a onClick={function () { setSelectedPage('SettingsPage'); return false; }}>{t('settings')}</a></li>
                {isLoggedIn ? <LogoutButton setIsLoggedIn={setIsLoggedIn} setSelectedPage={setSelectedPage} /> : <LoginButton setIsLoggedIn={setIsLoggedIn} setSelectedPage={setSelectedPage} />}
              </ul>
            </header>

            <div className="wrap-large pBxl" id="content">
              <SelectedPage selectedPage={selectedPage} setSelectedPage={setSelectedPage} setIsLoggedIn={setIsLoggedIn} />
            </div>

            <Footer setSelectedPage={setSelectedPage} selectedPage={selectedPage} />

          </WorkorderListProvider>
        </LoaderOverlayProvider>
      </Toaster>
    </>
  );
}

export default App;
