import { useEffect, useState } from "react";
import WorkorderService from "../services/workorder";

function CheckboxInformation(props: any) {
    let [init, setInit] = useState(false);
    let [isInvalid, setIsInvalid] = useState(false);
    const [checked, setChecked] = useState(false);

    const handleChange = () => {
        let newChecked = !checked;
        setChecked(newChecked);

        WorkorderService.getInstance().UpdateAnswer(props.question.id, 0, newChecked);
    };

    useEffect(() => {
        setIsInvalid(props.question.isMandatory && !checked);

        if (init === false) {
            setInit(true);

            if (props.question.answer !== null) {
                setChecked(props.question.answer.yesNo);
            } else {
                if (props.question.defaultValue?.yesNo !== null) {
                    setChecked(props.question.defaultValue.yesNo);
                }
            }
        }
    });

    return (
        <div>
            <input type="checkbox" name="value" id={props.question.id} onChange={handleChange} checked={checked} />
            <label htmlFor={props.question.id} style={{ color: "black" }} className={`break-line ${isInvalid ? "red100" : ""}`}>{props.question.label}</label>
        </div>
    );
}

export default CheckboxInformation;