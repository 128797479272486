import { useEffect, useState } from "react";
import WorkorderService from "../services/workorder";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Dependents from "../helpers/Dependents";

function DropBoxQuestion(props: any) {
    let [init, setInit] = useState(false);
    let [state, setState] = useState({
        value: ''
    });

    const [selectedOption, setSelectedOption] = useState('');
    const [options, setOptions] = useState<any[]>([]);
    let [isInvalid, setIsInvalid] = useState(false);

    let handleInputChange = (event: any) => {
        setState({ ...state, [event.value]: event.value });
        setIsInvalid(props.question.isMandatory && event.value === "");

        WorkorderService.getInstance().UpdateAnswer(props.question.id, props.question.itemType, { label: event.label, value: event.value });
        Dependents.handleDependents(props.question, event.value);
    };

    function LabelText() {
        if (props.question.isMandatory == true) {
            return (<>
                <label className="black">{props.question.label}</label><span style={{ color: "red" }}>*</span>
            </>)
        } else {
            return (
                <label className="black">{props.question.label}</label>
            )
        }
    }

    function createOptions(labels: string[], values: string[]): any[] {
        return labels.map((label: string, index: number) => { return { label: label, value: values[index] }; })
    }

    useEffect(() => {
        if (!init) {
            setInit(true);

            let options = createOptions(props.question.defaultValue.dropList.dropDownList, props.question.defaultValue.dropList.dropId);
            setOptions(options);

            if (props.question.answer !== null) {
                setSelectedOption(props.question.answer.DropList.DropIdSelected);
            }
            else if (props.question.defaultValue?.dropList?.dropIdSelected !== null) {
                setSelectedOption(props.question.defaultValue.dropList.dropIdSelected);
            }
            else {
                setIsInvalid(props.question.isMandatory);
            }
            Dependents.handleDependents(props.question);
        }
    }, []);

    return (
        <div className="grid-row">
            <div className="grid-cell w100 w50--m w50--l">
                <LabelText />
            </div>
            <Dropdown disabled={props.question.isReadOnly} className={`grid-cell w100 w50--m w50--l ${isInvalid ? "is-invalid" : ""}`} options={options} placeholder={props.question.placeHolder} onChange={handleInputChange} value={selectedOption} />
        </div>
    );
}

export default DropBoxQuestion;