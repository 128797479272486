import { useEffect, useState } from "react";
import WorkorderService from "../services/workorder";
import Select from 'react-dropdown';
import 'react-dropdown/style.css';
import Dependents from "../helpers/Dependents";

function DeviationQuestion(props: any) {
    let [init, setInit] = useState(false);

    const [checked, setChecked] = useState(false);
    const [selectedOption, setSelectedOption] = useState<string | undefined>('');
    const [options, setOptions] = useState<any[]>([]);
    const [isInvalid, setIsInvalid] = useState(false);

    const handleCheckBoxChange = () => {
        let newChecked = !checked;
        setChecked(newChecked);

        if (!newChecked) {
            setSelectedOption(undefined);
            setIsInvalid(true);
        }

        let newComment = newChecked ? selectedOption : null;

        WorkorderService.getInstance().UpdateAnswer(props.question.id, 0, { deviation: newChecked, text: newComment });
        Dependents.handleDependents(props.question, newChecked);
        setIgnoreValidation(newChecked);
    };

    let handleDropDownChange = (event: any) => {
        setSelectedOption(event.value)
        setIsInvalid(false);

        WorkorderService.getInstance().UpdateAnswer(props.question.id, 0, { deviation: checked, text: event.value });
    };

    function setIgnoreValidation(ignoreValidation: boolean) {
        let sectionsBeforeQuestion = document.querySelector("#formquestion-completedWithDeviations")?.closest(".w100.w100--ns.w100--m")?.previousElementSibling;
        let questionsBeforeQuestion = document.querySelector("#formquestion-completedWithDeviations")?.previousElementSibling;
        if (ignoreValidation) {
            sectionsBeforeQuestion?.classList.add("ignore-is-invalid");
            questionsBeforeQuestion?.classList.add("ignore-is-invalid");
        }
        else {
            sectionsBeforeQuestion?.classList.remove("ignore-is-invalid");
            questionsBeforeQuestion?.classList.remove("ignore-is-invalid");
       }
        
    }

    function createOptions(labels: string[], values: string[]): any[] {
        return labels.map((label: string, index: number) => { return { label: label, value: values[index] }; })
    }

    useEffect(() => {
        if (!init) {
            setInit(true);

            if (props.question.answer !== null) {
                setChecked(props.question.answer.deviation);
                setIgnoreValidation(props.question.answer.deviation);
            } else {
                if (props.question.defaultValue?.deviation !== null) {
                    setChecked(props.question.defaultValue.deviation);
                    setIgnoreValidation(props.question.defaultValue.deviation);
                }
            }

            let options = createOptions(props.question.defaultValue.dropList.dropDownList, props.question.defaultValue.dropList.dropId);
            setOptions(options);

            if (props.question.answer?.text != null && props.question.answer?.text !== "") {
                setSelectedOption(props.question.answer.text);
            }
            else if (props.question.defaultValue?.text != null && props.question.defaultValue?.text !== "") {
                setSelectedOption(props.question.defaultValue.text);
            }
            else {
                setIsInvalid(true);
            }
            Dependents.handleDependents(props.question);
        }
    }, []);

    return (
        <>
            <div className="grid-row">
                <input type="checkbox" name="value" id={props.question.id} onChange={handleCheckBoxChange} checked={checked} />
                <label htmlFor={props.question.id} style={{ color: "black" }} className={`break-line`}>{props.question.label}</label>
                <div style={{ "display": checked ? "block" : "none" }}>
                    <Select disabled={props.question.isReadOnly} className={`grid-cell w100 ${isInvalid ? "is-invalid" : ""}`} options={options} placeholder={props.question.placeHolder} onChange={handleDropDownChange} value={selectedOption} />
                </div>
            </div>
        </>
    );
}

export default DeviationQuestion;
