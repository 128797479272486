import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@volue/wave-react';
import WorkorderService from "../services/workorder";
import SessionService from "../services/session";
import Dependents from "../helpers/Dependents";

// 2022-10-06 This components was used for handling item type BarcodeWithVerification (no expectedValue) and BarCodeForVerification (has toVerifyItemId).
// These item type are now handled by their own component. Still, to handle work orders already downloaded, we need to support the item type for a while.
// BarCodeForVerification is identified by having toVerifyItemId
// BarcodeWithVerification is identified by not having toVerifyItemId or expectedValue

const BarCodeQuestion = (props: any) => {
  const { t } = useTranslation();

  let [init, setInit] = useState(false);

  let [manualTyping, setManualTyping] = useState(false);
  let [meterMatch, setMeterMatch] = useState(false);
  let [meterUnMatch, setMeterUnMatch] = useState(false);
  let [isInvalid, setIsInvalid] = useState(false);
  let [hasVerification, setHasVerification] = useState(false);
  let [useVerificationOnly, setUseVerificationOnly] = useState(false);
  let [validValueExternal, setValidValueExternal] = useState<string>("");

  const [meterNumber, setMeterNumber] = useState<string>('');

  useEffect(() => {
    let verificationOnly = props.question.toVerifyItemId != null; // Used to handle the verification type used by Lede

    setHasVerification(props.question.expectedValue != null);
    setUseVerificationOnly(verificationOnly);

    if (props.question.expectedValue != null) {
      if (meterNumber === props.question.expectedValue) {
        setMeterMatch(true);
      } else if (meterNumber !== "" && props.question.expectedValue !== "") {
        setMeterUnMatch(true);
      }
      if (meterNumber === "") {
        setMeterUnMatch(false);
      }
      Dependents.handleDependents(props.question, meterNumber);
    }
    if (validValueExternal != null && validValueExternal != "" && meterNumber == validValueExternal) {
      setMeterMatch(true);
    }
    if (verificationOnly) {
      setIsInvalid(props.question.isMandatory && meterNumber !== validValueExternal);
    }
    else {
      setIsInvalid(props.question.isMandatory && meterNumber === "");
    }

    if (init === false) {
      setInit(true);

      let storedMeterNumber = props.question.answer?.text;
      if (!verificationOnly && storedMeterNumber == null) { // verificationOnly uses default value for storing the target value.
        storedMeterNumber = props.question.defaultValue?.text;
      }
      if (storedMeterNumber == null) {
        storedMeterNumber = "";
      }
      setMeterNumber(storedMeterNumber);

      if (verificationOnly) {
        if (props.question.isVerified) {
          setMeterMatch(true);
        }
        setValidValueExternal(props.question.defaultValue?.text);
        if (storedMeterNumber == props.question.defaultValue?.text) {
          setMeterMatch(true);
        }
      }

      let barCodeQuestionId = SessionService.getInstance().getBarCodeQuestionId();
      if (barCodeQuestionId === props.question.id) {
        let newBarCodeValue = SessionService.getInstance().getBarCodeValue();
        if (newBarCodeValue !== null) {
          WorkorderService.getInstance().UpdateAnswer(props.question.id, props.question.questionType, newBarCodeValue);
          setMeterNumber(newBarCodeValue);
          SessionService.getInstance().removeBarCodeValue();
        }
        SessionService.getInstance().removeBarCodeQuestionId();
      }
    }
  }, [meterNumber]);

  function NoMatch() {
    if ((meterNumber !== props.question.expectedValue) && (meterUnMatch)) {
      return (
        <div>
          <span className="red100 "> {t('differentValuesEntered')} </span>
        </div>
      )
    } else {
      return (
        <div></div>
      )
    }
  }

  function ShowMeterNumber() {
    if (!hasVerification || useVerificationOnly) {
      return (
        <>
          <div className="grid-cell w100">{props.question.label}</div>
        </>
      )
    } else if (manualTyping) {
      return (
        <>
          <div className="grid-row">
            <div className="grid-cell w40">{props.question.label}</div>
            <div className="grid-cell w40">****************</div>
          </div>
          <div> {t('repeatOnceMore')} </div>
        </>
      )
    } else {
      return (
        <>
          <div className="grid-row">
            <div className="grid-cell w40">{props.question.label}</div>
            <div className="grid-cell w40">{props.question.expectedValue}</div>
          </div>
          <div> {t('repeatOnceMore')} </div>
        </>
      )
    }
  }

  function onClickValidation() {
    setMeterMatch(false);
    setMeterNumber("");
    WorkorderService.getInstance().UpdateAnswer(props.question.id, props.question.questionType, "");
  }

  function MeterNumberChecked() {
    if (props.question.isReadOnly) {
      return (
        <div className="grid-row">
          <div className="grid-cell w40">{props.question.label}</div>
          <div className="svgIcon svgIcon--validation gray100">
            <svg
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14M22 4 12 14.01l-3-3" />
            </svg>
          </div>
        </div>
      );
    } else {
      return (
        <div className="grid-row">
          <div className="grid-cell w40">{props.question.label}</div>
          <div className="grid-cell w33">{props.question.expectedValue}</div>
          <div className="svgIcon svgIcon--validation green100" onClick={onClickValidation}>
            <svg
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14M22 4 12 14.01l-3-3" />
            </svg>
          </div>
        </div>
      );
    }
  }

  let allowOnlyNumbers = (event: any) => {
    if (!event.key.match('[0-9]')) {
      event.preventDefault();
    }
  };

  let HandleInputChange = (event: any) => {
    if (event.target.value === "") {
      setManualTyping(false);
    } else {
      setManualTyping(true);
    }
    setMeterNumber(event.target.value);

    WorkorderService.getInstance().UpdateAnswer(props.question.id, props.question.questionType, event.target.value);
  }

  let OpenScanCamera = (event: any) => {
    SessionService.getInstance().setBarCodeQuestionId(props.question.id);
    props.setScanEanCode(true);
  }

  if (meterMatch) {
    return (
      <MeterNumberChecked />
    )
  } else if (props.question.isReadOnly) {
    return (
      <>
        <div className="grid-row">
          <div className="grid-cell w40">{props.question.label}</div>
          <div className="grid-cell w40">{props.question.defaultValue?.text}</div>
        </div>
      </>
    )
  } else {
    // Initial or the meter number don't match.
    return (
      <>
        <ShowMeterNumber />

        <div className="grid-row">
          <input autoComplete="off" type="text" style={{ "width": "calc(100% - 58px)", "margin": "4px 8px 4px 0" }} className={`grid-cell ${isInvalid ? "is-invalid" : ""}`} name="value" value={meterNumber} onKeyPress={allowOnlyNumbers} onChange={HandleInputChange} />

          <Tooltip placement="right" content={t('scan')}>
            <span className="iconCircle iconCircle--medium fill-accent100" onClick={OpenScanCamera}>
              <div className="svgIcon svgIcon--stroked svgIcon--scanQrCode">
                <svg
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path
                    d="M7.556 2H4.222A2.222 2.222 0 0 0 2 4.222v3.334m20 0V4.222A2.222 2.222 0 0 0 19.778 2h-3.334m0 20h3.334A2.222 2.222 0 0 0 22 19.778v-3.334m-20 0v3.334C2 21.005 2.995 22 4.222 22h3.334M6 18h4v-4H6m4 4H6v-4h4m-4-4h4V6H6m4 4H6V6h4m4 0h4v4h-4m4-4h-4v4h4m-4.08 8c-.11.009.119 0 .01 0h-.01m0-4c-.11.009.119 0 .01 0h-.01m4 0c-.11.009.119 0 .01 0h-.01m0 4c-.11.009.119 0 .01 0h-.01m-2-2c-.11.009.119 0 .01 0h-.01"
                  />
                </svg>
              </div>
            </span>
          </Tooltip>
          <br />
          <NoMatch />
        </div>
      </>
    )
  }
}

export default BarCodeQuestion;
