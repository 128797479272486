import React, { useContext, useEffect, useState } from "react";
import WorkorderListElement from "../components/WorkorderListElement";
import '../i18n/config';
import { useTranslation } from 'react-i18next';
import DatabaseService from "../services/database";
import { LoaderOverlayContext } from "../context/LoaderOverlay";

function OverviewPage(props: any) {
    const { t } = useTranslation();
    const { hideLoader, showLoader } = useContext(LoaderOverlayContext);

    const [workorders, setWorkorders] = useState(<></>);

    useEffect(() => {

        if (showLoader) showLoader();

        DatabaseService.getInstance().getStoredWorkordersAsync().then(ws => {
            if (ws !== null) {
                if (ws.length === 0) return;

                let mapped = ws.map(e => {
                    return <WorkorderListElement key={e.id} e={e} setSelectedPage={props.setSelectedPage} enableNavigation= {true} />
                })

                setWorkorders(<>{mapped}</>);
            }
        });

        setTimeout(() => {
            if (hideLoader) hideLoader();
        }, 400);

    }, []);

    return (
        <div className="section">
            <div className="grid">
                <div className="wrap portalAppHeaderMargin">
                    <h1 className="pBs bdrBs mBs app-border accent100">{t('workorderList')}</h1>
                    <div className="w100">
                        <div className="grid-row">
                            {workorders}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OverviewPage;