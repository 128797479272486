import { useEffect, useState } from "react";
import WorkorderService from "../services/workorder";

function SetDateProduction(props: any) {
    let [init, setInit] = useState(false);
    let [date, setDate] = useState('');
    let [time, setTime] = useState('');

    useEffect(() => {
        if (init === false) {
            setInit(true);

            let answerWholeNumber;
            if (props.question.answer) {
                answerWholeNumber = props.question.answer.wholeNumber;
            } else {
                answerWholeNumber = props.question.defaultValue?.wholeNumber;
            }

            if (answerWholeNumber) {
                let anwerDate = new Date(answerWholeNumber);
                let month = anwerDate.getMonth() + 1; // Januar is 0

                let newDate = anwerDate.getFullYear() + "-";
                newDate += (month > 9 ? "" : "0") + month + "-"
                newDate += (anwerDate.getDate() > 9 ? "" : "0") + anwerDate.getDate();

                let newTime = (anwerDate.getHours() > 9 ? "" : "0") + anwerDate.getHours() + ":";
                newTime += (anwerDate.getMinutes() > 9 ? "" : "0") + anwerDate.getMinutes();

                setDate(newDate);
                setTime(newTime);
            }
        }
    });

    const UpdateDateTimeAndEpoch = (newDate: string, newTime: string) => {
        if (newDate?.length == 10 && newTime?.length == 5) {
            // Only update if both date and time is sat
            let newEpoch = new Date(newDate + "T" + newTime).getTime();
            WorkorderService.getInstance().UpdateAnswer(props.question.id, 0, newEpoch);
        }
    }

    const handleChangeDate = (event: any) => {
        setDate(event.target.value);
        UpdateDateTimeAndEpoch(event.target.value, time);
    }

    const handleChangeTime = (event: any) => {
        setTime(event.target.value);
        UpdateDateTimeAndEpoch(date, event.target.value);
    }

    let todayObj = new Date();
    let dd = String(todayObj.getDate()).padStart(2, '0');
    let mm = String(todayObj.getMonth() + 1).padStart(2, '0');
    let yyyy = String(todayObj.getFullYear());
    let today:string = yyyy + '-' + mm + '-' + dd;

    let maxTimeAsString = todayObj.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    let maxTime = parseInt(maxTimeAsString.replace(':', ''));
    let timeAsInt = parseInt(time.replace(':', ''));

    let dateYear = parseInt(date.substring(0,4));
    let dateMonth = parseInt(date.substring(5,7));
    let dateDay = parseInt(date.substring(8));
    let dateEpoch = new Date(dateYear, dateMonth - 1, dateDay).getTime() / 1000;
    let todayEpoch = todayObj.getTime() / 1000;

    let checkDate = () :boolean => {
        if(dateEpoch > todayEpoch) {
            return true;
        } else {
            return false;
        }
    }

    let checkTime = () :boolean => {
        if(!checkDate()) {
            return date === today && timeAsInt > maxTime;
        } else {
            return true;
        }
    }

    return (
        <div>
            <div className="grid-cell w100 ">{props.question.label}</div>
            <div className="grid-row">
                <div className="grid-cell w30">Dato</div>
                <input className={`grid-cell w70 ${date == "" || checkDate() ? "is-invalid" : ""}`} type="date" value={date} max={today} onChange={handleChangeDate} />
                <br></br>
                <div className="grid-cell w30">Tidspunkt</div>
                <input className={`grid-cell w70 ${time == "" || checkTime() ? "is-invalid" : ""}`} type="time" value={time} onChange={handleChangeTime} />
            </div>
            <div className="grid-row">
                <span className="grid-cell w70 red100" style={{ "display": checkDate() || checkTime() ? "block" : "none" }}>Velg et tidspunkt tilbake i tid</span>
            </div>
        </div>
    );
}

export default SetDateProduction;
