import { deleteDB, openDB } from 'idb';
import SessionService from "./session";

// The library idb is a wrapper around IndexedDB to make it easier to use. 
// For simple tutorial on how to use idb, see: https://hackernoon.com/use-indexeddb-with-idb-a-1kb-library-that-makes-it-easy-8p1f3yqq

class DatabaseService {
    private static databaseName: string = 'AppDB';
    private static instance: DatabaseService;

    static getInstance(): DatabaseService {
        if (!DatabaseService.instance) {
            DatabaseService.instance = new DatabaseService();

            openDB(DatabaseService.databaseName, 1, {
                upgrade(db) {
                    db.createObjectStore('DownloadedWorkorders');
                }
            });
        }
        return DatabaseService.instance;
    }

    private constructor() { }

    async dbCreatedSuccessfullyCheck() {
        const db = await openDB(DatabaseService.databaseName, 1);

        if (db.objectStoreNames.length < 1) {
            //DBs not created successfully .. trying to create again
            await deleteDB(DatabaseService.databaseName).then(async () => {

                await openDB(DatabaseService.databaseName, 1, {
                    upgrade(db) {
                        db.createObjectStore('DownloadedWorkorders');
                    }

                }).then(() => {
                    if (db.objectStoreNames.length > 0) return true;
                })
            })
            return false;
        }
        else return true;
    }

    async saveWorkorderAsync(workorder: any) {
        await this.removeFromStoredWorkordersAsync(workorder.id);
        await this.addToStoredWorkordersAsync(workorder);
    }

    async getStoredWorkordersAsync(): Promise<any[]> {
        let result: any[] = [];
        var dbsOk = await this.dbCreatedSuccessfullyCheck();
        if (dbsOk) {
            const db1 = await openDB(DatabaseService.databaseName, 1);
            let dbKeys = await db1.getAllKeys("DownloadedWorkorders");

            if (dbKeys.length > 0) {
                const userName = SessionService.getInstance().getUsername();

                if (userName) {
                    let userKeys = dbKeys.filter((dbKey) => this.isUsersDbKey(dbKey, userName));
                    for (const dbKey of userKeys) {
                        let workorder = await db1.get("DownloadedWorkorders", dbKey);
                        result.push(workorder);
                    }
                }
            }
        }

        return Promise.resolve(result);
    }

    async getStoredWorkorderAsync(workorderId: string) {
        let workorders = await this.getStoredWorkordersAsync();

        let workorder = workorders.filter((e) => {
            return e.id === workorderId;
        });

        if (workorder.length !== 0) {
            return workorder[0];
        } else {
            return null;
        }
    }

    async addToStoredWorkordersAsync(workorder: any) {
        const dbKey = this.createDbKey(workorder.id);
        const db1 = await openDB(DatabaseService.databaseName, 1);
        await db1.put("DownloadedWorkorders", workorder, dbKey);
    }

    async removeFromStoredWorkordersAsync(workorderId: string) {
        const db1 = await openDB(DatabaseService.databaseName, 1);
        const allDbKeys = await db1.getAllKeys("DownloadedWorkorders");
        // Make sure we match with dbKeys created before it was sat to all uppercase
        const removeKeys = allDbKeys.filter((dbKey) => dbKey.toString().toUpperCase() == this.createDbKey(workorderId));

        for (const dbKey of removeKeys) {
            await db1.delete("DownloadedWorkorders", dbKey);
        }
    }

    isUsersDbKey(dbKey: IDBValidKey, userName: string) {
        const lengthOfGuidAndSeperator = 33; // 32 char length of GUID and 1 char length of seperator '@'

        let expectedLengthOfDbKey = lengthOfGuidAndSeperator + userName?.length;
        return dbKey.toString().toUpperCase().endsWith("@" + userName?.toUpperCase()) && dbKey.toString().length == expectedLengthOfDbKey;
    }

    createDbKey(workorderId: string) {
        let username = SessionService.getInstance().getUsername();
        return workorderId + "@" + username?.toUpperCase();
    }
}

export default DatabaseService;
