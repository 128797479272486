import { useEffect, useState } from "react";
import WorkorderService from "../services/workorder";

function MeterInformation(props: any) {

    let [init, setInit] = useState(false);

    let [state, setState] = useState({
        label: '',
        text: ''
    });

    const handleChange = (e: any) => {
        console.log(e.target.value);
        //setState()
        setState(e.target.value);
        WorkorderService.getInstance().UpdateAnswer(props.question.id, 8, e.target.value);
    }

    useEffect(() => {

        
        if (init === false) {
            setInit(true);
            if (props.question.answer !== null) {
                setState({ text: props.question.answer.text, label: props.question.label });
            } else {
                setState({ text: props.question.defaultValue.text, label: props.question.label });
            }
        }
    });

    return (
        <div className="grid-row">
            <div className="grid-cell w30">{state.label}</div>

            <input autoComplete="off" type="text" className="grid-cell w40" name="value" defaultValue={state.text} onChange={handleChange}/>
        </div>

    );
       
}

export default MeterInformation;