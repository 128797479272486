import DatabaseService from "./database";
import DebugService from "./debug";
import SessionService from "./session";

export class UpdateAnswerItem {
    readonly itemId: string;
    readonly questionType: number;
    readonly answer: any;

    constructor(itemId: string, questionType: number, answer: any) {
        this.itemId = itemId;
        this.questionType = questionType;
        this.answer = answer;
    }
}

class WorkorderService {
    updateAnswerQueue: UpdateAnswerItem[] = [];
    ongoingUpdate = false;

    private static instance: WorkorderService;

    static getInstance(): WorkorderService {
        if (!WorkorderService.instance) {
            WorkorderService.instance = new WorkorderService();
            WorkorderService.instance.UpdateAnswersFromQueueJob(); // Start function that triggers itself
        }

        return WorkorderService.instance;
    }

    public SetWorkorder(workorder: any) {
        SessionService.getInstance().setWorkorder(workorder);
    }

    public async UpdateAnswer(itemId: string, questionType: number, answer: any): Promise<void> {
        let update = new UpdateAnswerItem(itemId, questionType, answer);
        this.updateAnswerQueue.push(update);
        // console.log("Queued answer " + update.itemId + " with value " + answer);
    }

    public HasQueuedOrOngoingUpdates(): boolean {
        // console.log("HasQueuedOrOngoingUpdates with queue length " + this.updateAnswerQueue.length + " and ongoingUpdate: " + this.ongoingUpdate);
        return this.updateAnswerQueue.length > 0 || this.ongoingUpdate;
    }

    // Calls from UI changes run in parallell, so we get race condition if we call saveWorkorderAsync directly.
    // Therefore we send updates to a queue and regularly updates from the queue synchronously.
    // Consider replacing current approach if there is a better way to handle it.
    private async UpdateAnswersFromQueueJob(): Promise<void> {
        if (this.updateAnswerQueue.length) {
            // console.log("UpdateAnswersFromQueueJob: " + this.updateAnswerQueue.length + " items in queue");
        }
        else {
            // console.log("UpdateAnswersFromQueueJob: Nothing to update");
        }

        this.ongoingUpdate = this.updateAnswerQueue.length > 0;
        while (this.updateAnswerQueue.length) {
            let update = this.updateAnswerQueue.shift();
            if (update) {
                let updateDeprectedByLaterUpdateOfQuestion = this.updateAnswerQueue.some((x: UpdateAnswerItem) => { return x.itemId == update?.itemId })

                if (!updateDeprectedByLaterUpdateOfQuestion) {
                    await this.HandleUpdateAnswer(update.itemId, update.questionType, update.answer);
                    // console.log("Updated answer " + update.itemId + " to " + update.answer + " from queue");
                }
            }
        }
        this.ongoingUpdate = false;
        setTimeout(() => {
            WorkorderService.instance.UpdateAnswersFromQueueJob();
        }, 200);
    }

    private async HandleUpdateAnswer(itemId: string, questionType: number, answer: any): Promise<void> {
        // For testing parallell calls
        // await new Promise(f => setTimeout(f, 500 + Math.floor(Math.random() * 500)));

        // console.log('Updating workorder answer ' + itemId + " with value " + answer);

        let selectedWorkorderId = SessionService.getInstance().getSelectedWorkorderId();
        let workorder = await DatabaseService.getInstance().getStoredWorkorderAsync(selectedWorkorderId);
        if (workorder != null) {

            for (let pI in workorder.pages) {
                let page = workorder.pages[pI];

                for (let sI in page.sections) {
                    let section = page.sections[sI];

                    for (let qI in section.items) {

                        let item = section.items[qI];
                        if (item.id === itemId) {
                            switch (item.itemType) {
                                case 0:
                                    item.answer = { text: answer };
                                    break;
                                case 1:     // Text
                                    item.answer = { text: answer };
                                    break;
                                case 2:     // Yes/no
                                    if (questionType !== 20) {
                                        item.answer = { yesNo: answer };
                                    } else { // if questiontype = 20, image on problemAnswer
                                        item.problemAnswer = {
                                            camera: {
                                                image: answer
                                            }
                                        }
                                    }
                                    break;
                                case 3:     // Whole number
                                    item.answer = { wholeNumber: answer != "" ? parseInt(answer) : null };
                                    break;
                                case 4:     // Decimal number
                                    item.answer = { decimalNumber: answer != "" ? parseFloat(answer) : null };
                                    break;
                                case 5:     // Image
                                    item.answer = {
                                        images: answer
                                    }
                                    if (item.defaultValue?.images !== null) {
                                        item.defaultValue.images = null;
                                    }

                                    break;
                                case 6:     // Drop down
                                    //item.answer = { text: answer };
                                    item.answer = {
                                        DropList: {
                                            DropDownListSelected: answer.label,
                                            DropIdSelected: answer.value
                                        }
                                    }
                                    break;
                                case 7:     // bar code
                                    if (questionType === 5) {
                                        item.problemAnswer = {
                                            camera: {
                                                image: answer
                                            }
                                        } // Text input
                                    } else if (questionType === 6) {
                                        item.problemDescription = answer;
                                    } else {
                                        item.answer = { text: answer };
                                    }

                                    break;
                                case 8:     // Camera
                                    item.answer = { text: answer };
                                    break;
                                case 12:     // Comment
                                    item.answer = { text: answer };
                                    break;
                                case 15:    // checkbox
                                    item.answer = { yesNo: answer };
                                    break;
                                case 16:    //MeterData
                                    item.answer = { text: answer };
                                    break;
                                case 17:    //Signature
                                    item.answer = {
                                        camera: {
                                            image: answer,
                                            fileName: "Avslutning.png"
                                        }
                                    }
                                    break;
                                case 19:    //SetDateProduction
                                    item.answer = { wholeNumber: answer };
                                    break;
                                case 21:     // Deviation
                                    item.answer = answer;
                                    break;
                                case 22:     // BarCodeWithVerification
                                case 23:     // BarCodeForVerification
                                    item.answer = { text: answer };
                                    break;
                                default:
                                    console.error(`WorkorderService: itemType ${item.itemType} is unhandled`)
                                    break;
                            }
                        }
                    }
                }
            }

            // Save workorder locally
            await DatabaseService.getInstance().saveWorkorderAsync(workorder);
        }
    }
}

export default WorkorderService;