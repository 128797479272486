import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import WorkorderService from "../services/workorder";

function WholeNumberQuestion(props: any) {
    const { t } = useTranslation();
    let [isInvalid, setIsInvalid] = useState(false);
    let [showVerificationSection, setShowVerificationSection] = useState(false);
    let [showVerificationMark, setShowVerificationMark] = useState(false);
    let [init, setInit] = useState(false);
    let [verifyNumber, setVerifyNumber] = useState('')
    let [state, setState] = useState({
        value: ''
    });
    let [hideValue, setHideValue] = useState(false);

    function UpdateAnswer(wholeNumber: string, wholeNumberVerified: string) {
        let newAnswer = wholeNumber == wholeNumberVerified ? wholeNumber : "";

        WorkorderService.getInstance().UpdateAnswer(props.question.id, props.question.questionType, newAnswer);
        // console.log("updated answer to '" + newAnswer + "'");
    }

    let handleInputChange = (event: any) => {
        let targetName = event.target.name;
        let updatedAnswer = event.target.value;

        setState({ ...state, [targetName]: updatedAnswer });

        // Don't update answer if invalid value. Like if it contains a letter.
        if (updatedAnswer && updatedAnswer.match(/^[0-9]+$/) == null) {
            return;
        }

        UpdateAnswer(updatedAnswer, verifyNumber);
    };

    let handleVerificationInputChange = (event: any) => {
        setVerifyNumber(event.target.value);

        UpdateAnswer(state.value, event.target.value);
    };

    let allowOnlyNumbers = (event: any) => {
        if (!event.key.match('[0-9]')) {
            event.preventDefault();
        }
    };

    useEffect(() => {
        let valueFilledOut = state.value !== "" && state.value != null;
        let valueNeedsVerification = valueFilledOut && state.value != verifyNumber;

        setIsInvalid(props.question.isMandatory && (!valueFilledOut || valueNeedsVerification));
        setShowVerificationSection(valueNeedsVerification);
        setShowVerificationMark(valueFilledOut && !valueNeedsVerification);
        if(showVerificationMark) {
            setHideValue(false);
        }

        if (init === false) {
            setInit(true);

            let existingAnswer = "";
            if (props.question.answer) {
                existingAnswer = props.question.answer.wholeNumber != null ? props.question.answer.wholeNumber + "" : "";
            } else {
                existingAnswer = props.question.defaultValue?.wholeNumber != null ? props.question.defaultValue.wholeNumber + "" : "";
            }

            setState({ value: existingAnswer });
            setVerifyNumber(existingAnswer);
        }
    });

    return (<div className="grid-row">
        <div>
            <label className="black">{props.question.label}</label><span style={{ color: "red", "display": props.question.isMandatory ? "inline-block" : "none" }}>*</span>
            <br />
            <input
                type={ hideValue ? "text" : "number" }
                autoComplete="off"
                name="value"
                value= { hideValue ? "*****" : state.value }
                className={`grid-cell ${isInvalid ? "is-invalid" : ""}`}
                style={{ "width": "calc(100% - 48px)" }}
                onKeyPress={allowOnlyNumbers}
                onChange={handleInputChange} />
            <div className="svgIcon svgIcon--medium svgIcon--validation green100" style={{ "margin": "8px 0px 0px 12px", "display": showVerificationMark ? "inline-block" : "none" }}>
                <svg
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14M22 4 12 14.01l-3-3" />
                </svg>
            </div>
        </div>
        <div style={{ "display": showVerificationSection ? "block" : "none" }}>
            <label className="black">{t('repeatOnceMore')}</label><br />
            <input
                type="number"
                autoComplete="off"
                value={verifyNumber}
                className="grid-cell is-invalid"
                style={{ "width": "calc(100% - 48px)" }}
                onKeyPress={allowOnlyNumbers}
                onChange={handleVerificationInputChange}
                onFocus={()=>{
                    if(state.value !== "") { setHideValue(true); }
                    else { setHideValue(false); }
                }}
                onBlur={()=>{ setHideValue(false); }}
                />
        </div>
    </div>)
}

export default WholeNumberQuestion;
