import { useEffect, useState } from "react";
import React from "react";
import { useTranslation } from 'react-i18next';

function SettingsPage(props: any) {
    const { i18n, t  } = useTranslation();

    let [language, setLanguage] = useState('');

    let languages = [{ label: "English", code: 'en' }, { label: "Svenska", code: 'se' }, { label: "Norsk", code: 'no' }]
    const [languageOptionsComponents, setLanguageOptionsComponents] = useState(<></>);

    let handleChange = (event: any) => {

        let targetValue = event.target.value;
        let updatedAnswer = targetValue;

        localStorage.setItem('Language', updatedAnswer);
        setLanguage(updatedAnswer);

        i18n.changeLanguage(event.target.value);
    };

    useEffect(() => {

        let lang = localStorage.getItem("Language");

        if (lang !== null) {
            setLanguage(`${lang}`);
        } else {
            lang = 'en'
        }

        let mappedLanguages = languages.map((e) => {
            return (
                <div key={e.code}>
                    <input type="radio" name="r2" id={e.code} value={e.code} onChange={handleChange} checked={lang === e.code} />
                    <label className="btn btn--outline accent120 w100" htmlFor={e.code}>{e.label}</label>
                </div>
            )
        });

        setLanguageOptionsComponents(<>{mappedLanguages}</>);

    }, [language]);

    return (
        <>
            <div className="section">
                <div className="grid">
                    <div className="wrap portalAppHeaderMargin">
                        <h1 className="pBs bdrBs mBs app-border accent100">{t('settings')}</h1>

                        <div className="w100 w70--ns w70--m">
                            <div className="grid-row">
                                <div className="grid-cell w100">
                                    <label className="mTm">
                                        <h2 className="pBs mBs app-border" style={{ color: "black" }}>{t('language')}</h2>
                                        <div className="mTs measure">
                                            {languageOptionsComponents}
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className="grid-row">
                                <div className="grid-cell w100">
                                    {/*
                                    <label className="mTm">
                                        <h2 className="pBs mBs app-border" style={{ color: "black", display: "inline" }}>Clean DB</h2>
                                        <button type="button" style={{ float: "right" }} className="btn btn--small btn--narrow fill-navy100" >Clean DB</button>
                                    </label>
                                    */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SettingsPage;