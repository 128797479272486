import { useEffect, useState } from "react";
import Resizer from "react-image-file-resizer";
import WorkorderService from "../services/workorder";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { useTranslation } from 'react-i18next';


const CameraQuestion = (props: any) => {
  const [init, setInit] = useState(false);
  const [images, setImages] = useState<any[]>([]);
  const [isInvalid, setIsInvalid] = useState(false);

  useEffect(() => {
    setIsInvalid(props.question.isMandatory && images.length < 1);

    if (!init) {
      let answerImages = props.question.answer?.images;
      if (answerImages) {
        setImages(answerImages);
      }
      else {
        let defaultValueImage = props.question.defaultValue?.images;
        if (defaultValueImage) {
          setImages(defaultValueImage);
        }
      }

      setInit(true);
    }
  }, [init]);

  let OpenCamera = (event: any) => {
    document.getElementById('cameraInput-' + props.question.id)?.click();
  }

  let OpenImage = (event: any) => {
    document.getElementById('imageInput-' + props.question.id)?.click();
  }

  function handleCapture(target: any) {
    // console.log(target.files[0]);
    if (target.files[0]) {
      try {
        Resizer.imageFileResizer(
          target.files[0],
          1024,
          1024,
          "JPEG",
          70,
          0,
          (base64image: any) => {
            if (base64image) {
              let newImage = {
                image: base64image,
                fileName: target.files[0].name,
                mimeType: target.files[0].type,
              }
              images.unshift(newImage);
              setImages([...images]);
              WorkorderService.getInstance().UpdateAnswer(props.question.id, 0, images);
              setIsInvalid(false);
            }
          },
          "base64"
        );
      } catch (error) {
        console.error(error);
      }
    }
  }

  let DeleteImage = (fileName: string) => {
    let newImages = images.filter(x => x.isThumbnail || x.fileName != fileName);

    WorkorderService.getInstance().UpdateAnswer(props.question.id, 0, newImages);

    setImages(newImages);
    setIsInvalid(props.question.isMandatory && newImages.length < 1);
  }

  function ShowImageOrCarousel() {
    if (images.length <= 1) {
      return (<>
        {images.map((image: any, index: number) => <DisplayImage key={`images-${props.question.id}-${index}`} questionId={props.question.id} image={image} />)}
      </>);
    }
    else {
      return (
        <Carousel swipeable={false} showThumbs={false} dynamicHeight={false} showIndicators={false} statusFormatter={(currentItem: Number, total: number) => `${currentItem} av ${total}`}>
          {images.map((image: any, index: number) =>
              <DisplayImage key={`images-${props.question.id}-${index}`} questionId={props.question.id} image={image} />
          )}
        </Carousel>
      );
    }
  }

  function DisplayImage(props: any) {
    const { t } = useTranslation();
    let [description, setDescription] = useState<string>(props.image?.description ?? "");
    let [zoomMode, setZoomMode] = useState(false);

    let UpdateDescription = (event: any) => {
      let newDescription = event.target.value;
      images.filter(x => !x.isThumbnail && x.fileName == props.image?.fileName).forEach(x => x.description = newDescription);

      WorkorderService.getInstance().UpdateAnswer(props.questionId, 0, [...images]);
      setDescription(newDescription);
    }

    return (
      <div style={{ paddingBottom: "20px", position: "relative"}}>
        <div style={{ textAlign: "left", position: "absolute", left: "8px", top: "8px", display: !props.image?.isThumbnail ? "block" : "none" }} className="zoom-button">
          <span className="iconCircle iconCircle--medium fill-accent100" onClick={(event: any) => zoomMode ? setZoomMode(false) : setZoomMode(true)}>
            {
              !zoomMode ?
                <div className="svgIcon svgIcon--zoomIn">
                  <svg
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path
                      d="m21 21-4.35-4.35M11 19a8 8 0 1 0-8-8m8 8a8 8 0 1 1 8-8m-8-3v6m-3-3h6"
                    />
                  </svg>
                </div>
              :
              <div className="svgIcon svgIcon--zoomOut">
                <svg
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="m21 21-4.35-4.35M11 19a8 8 0 1 0-8-8m8 8a8 8 0 1 1 8-8M8 11h6" />
                </svg>
              </div>
            }
          </span>
        </div>
        <h5 style={{ textAlign: "left", position: "absolute", left: "65px", top: "20px", display: zoomMode ? "block" : "none" }} className="fill-gray50 zoom-text">{t('imageZoomOutText')}</h5>
        <div className={zoomMode ? "scrollable" : ""}>
          <img src={props.image?.image} style={{ width: "100%" }} className={zoomMode ? "scrollable" : ""} />
        </div>
        <textarea disabled={props.image?.isThumbnail}
          value={description}
          className="grid-cell w100"
          onChange={UpdateDescription} />
        <div style={{ margin: "5px 0", textAlign: "right", display: props.image?.image && !props.image?.isThumbnail ? "block" : "none" }} id={`delete-${props.questionId}`} >
          <span className="iconCircle iconCircle--medium fill-accent100" onClick={(eveny: any) => DeleteImage(props.image?.fileName)}>
            <div className="svgIcon svgIcon--delete">
              <svg
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M3 6h18-2v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6h3V4a2 2 0 012-2h4a2 2 0 012 2v2m-6 5v6m4 0v-6" />
              </svg>
            </div>
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="grid-row">
      <div style={{ float: "right", marginBottom: "5px" }}>
        <span className={`iconCircle iconCircle--medium fill-accent100 ${isInvalid ? "is-invalid" : ""}`} onClick={OpenImage}>
          <div className="svgIcon svgIcon--image">
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M8.5 10A1.5 1.5 0 1 0 7 8.5M8.5 10A1.5 1.5 0 0 1 7 8.5M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2m0 0 11-11 5 5" />
            </svg>
          </div>
        </span>
        <span className={`iconCircle iconCircle--medium fill-accent100 ${isInvalid ? "is-invalid" : ""}`} style={{ marginLeft: "10px" }} onClick={OpenCamera}>
          <div className="svgIcon svgIcon--camera">
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M23 8a2 2 0 00-2-2h-4l-2-3H9L7 6H3a2 2 0 00-2 2v11a2 2 0 002 2h18a2 2 0 002-2M1 8a2 2 0 012-2h4l2-3h6l2 3h4a2 2 0 012 2v11a2 2 0 01-2 2H3a2 2 0 01-2-2m11-2a4 4 0 10-4-4m4 4a4 4 0 114-4" />
            </svg>
          </div>
        </span>
      </div>
      <div style={{ "width": "calc(100% - 52px)" }}>{props.question.label}</div>
      <div style={{ "clear": "both", marginBottom: "5px" }} />
      <input
        type="file"
        style={{ display: "none" }}
        accept="image/jpeg,image/png,image/heif"
        capture="environment"
        id={`cameraInput-${props.question.id}`}
        name="cameraInput"
        onChange={(e) => handleCapture(e.target)}
      />
      <input
        type="file"
        style={{ display: "none" }}
        accept="image/jpeg,image/png,image/heif"
        id={`imageInput-${props.question.id}`}
        name="imageInput"
        onChange={(e) => handleCapture(e.target)}
      />

      <ShowImageOrCarousel />
    </div>
  );
};

export default CameraQuestion;
